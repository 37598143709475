import { Reducer } from "redux";
import States from "./state";
import { TypesAction } from "../typesAction";

const initialState: States = {
  listNotification: [],
  tokenPush: "",
  current_page: 1,
  last_page: 1,
  ids: [],
  loadingNoti: false,
  count_noti: 0,
};

const ListNotiReducer: Reducer<States> = (state = initialState, action) => {
  switch (action.type) {
    case TypesAction.GET_LIST_NOTIFICATION:
      return {
        ...state,
        listNotification: action?.payload?.dataNoti,
        current_page: action?.payload?.current_page,
        last_page: action?.payload?.last_page,
        loadingNoti: action?.payload?.loadingNoti,
      };
    case TypesAction.TOKEN_PUSH:
      return {
        ...state,
        tokenPush: action.payload,
      };
    case TypesAction.ADD_IDS:
      return {
        ...state,
        result: [...state.ids, action.payload],
      };
    case TypesAction.DELETE_IDS:
      const arr = state.listNotification?.filter((item) => {
        return !action.payload.includes(item?.id);
      });

      return {
        ...state,
        listNotification: arr,
      };
    case TypesAction.COUNT_NOTI:
      return {
        ...state,
        count_noti: action?.payload,
      };
    default:
      return state;
  }
};
export default ListNotiReducer;
