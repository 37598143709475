import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { BtnPlaceYourOrder } from "../Cart.style";
import checkedImage from "../../../assets/Images/check.png";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "75%", sm: "50%", md: "35%" },
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
  textAlign: "center",
};

interface ModalProps {
  title: string;
  content: string;
  isOpen: boolean;
  handleClose?: () => void;
}

export default function BasicModal(props: ModalProps) {
  const { title, content, isOpen, handleClose } = props;

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Box sx={style}>
        <Box
          mb={5}
          component={"img"}
          src={checkedImage}
          style={{ width: 120, height: 120 }}
        />
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {title}
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          {content}
        </Typography>
        <Button onClick={handleClose}>
          <BtnPlaceYourOrder>OK</BtnPlaceYourOrder>
        </Button>
      </Box>
    </Modal>
  );
}
