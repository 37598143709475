import { Box, CircularProgress } from "@mui/material";
import { useLocation, useNavigate } from "react-router";
import { theme } from "../../utils/theme";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import { callHttpClientMethod } from "../../services/callHttpClient";
import { BASE_URL, HttpMethod } from "../../services/api.constant";
import { notification } from "../../services/apiList";
import { TypesAction } from "../../redux/typesAction";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux";
import { useEffect } from "react";

function Noti() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { count_noti } = useSelector(
    (state: RootState) => state.listNotification
  );

  const { tokenPush } = useSelector(
    (state: RootState) => state.listNotification
  );

  const handleClick = (event: any) => {
    navigate("/notifications");
  };

  return (
    <Box
      sx={{
        color:
          location.pathname === "/notifications"
            ? "#007F18"
            : ` ${theme.palette.text.body}`,
        position: "relative",
      }}>
      {count_noti > 0 && (
        <Box
          sx={{
            width: 20,
            height: 20,
            backgroundColor: "red",
            position: "absolute",
            right: "0",
            top: "-4px",
            borderRadius: 10,
            color: "white",
            fontSize: 11,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}>
          {count_noti}
        </Box>
      )}
      <NotificationsOutlinedIcon
        onClick={handleClick}
        sx={{ width: "38px", height: "38px" }}
      />
    </Box>
  );
}

export default Noti;
