import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import ScrollToArrow from "../../components/ScrollToArrow";

import ItemDetail from "./components/ItemDetail";
const ProductDetails = () => {
  return (
    <>
      <Box
        width="100%"
        sx={{
          backgroundSize: "cover",
          marginTop: -3,
          backgroundColor: "transparent",
          // marginLeft: { xs: "20px" },
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}>
        <Container
          maxWidth={"lg"}
          style={{
            background: "transparent",
            color: "#000",
            padding: 0,
          }}>
          <ItemDetail />
        </Container>
      </Box>
      <ScrollToArrow />
    </>
  );
};

export default ProductDetails;
