import { TableCell } from "@mui/material";
import styled from "@emotion/styled";
import { theme } from "../../../../utils/theme";
export const Table = styled.table`
  width: 100%;
`;

export const TableRow = styled.tr`
  &:hover {
    background: #fafafa;
  }
`;

export const Th = styled(TableCell)`
  padding: 15px;
  text-align: center;
  border: none;
`;
export const Td = styled(TableCell)`
  padding: 10;
  text-align: center;
`;
export const BoxItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const BuyAgain = styled.button`
  border-radius: 25px;
  border: 1px solid rgba(135, 135, 135, 0.5);
  padding: 5px 5px;
  font-size: 10px;
  text-transform: uppercase;
  color: ${theme.palette.text.primary};
  background-color: ${theme.palette.background.default};
`;
