import React, { memo, useEffect, useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import plantTree from "./assets/Plant-tree.png";
import plantLogo from "./assets/Plant-logo.png";
import plasticLogo from "./assets/PlasticPot-logo.png";
import plasticItem from "./assets/Plastic-item.png";
import plantMobile from "./assets/plant-mobile.png";
import potMobile from "./assets/pot-mobile.png";
import "./style.scss";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Pages from "..";
import { RootState } from "../../redux";
import { TypesAction } from "../../redux/typesAction";
import { Box } from "@mui/material";
import LargeText from "../../components/Text/LargeText";
import DefaultText from "../../components/Text/DefaultText";

import banner1 from "../../assets/Images/banner1.webp";
import banner2 from "../../assets/Images/banner2.webp";
import banner3 from "../../assets/Images/banner3.webp";
import uuid from "react-uuid";
import { callHttpClientMethod } from "../../services/callHttpClient";
import { BASE_URL, HttpMethod } from "../../services/api.constant";
import { saveTokenFirebase } from "../../services/apiList";

const HomePage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { auth, user } = useSelector((state: RootState) => state.auth);
  const { tokenPush } = useSelector(
    (state: RootState) => state.listNotification
  );

  useEffect(() => {
    if (auth?.access_token) handlesaveTokenFirebase();
  }, []);
  const handlesaveTokenFirebase = async () => {
    const dataToken = {
      token_push: tokenPush,
      platform: "WEB",
      user_id: user?.id,
      user_type: user?.type,
      status: 1,
      uuid: uuid(),
      app_version: "1.2.0",
    };

    const respon = await callHttpClientMethod(
      BASE_URL + saveTokenFirebase(),
      HttpMethod.Post,
      dataToken,
      {
        headers: {
          Authorization: `Bearer ${auth?.access_token}`,
        },
      }
    );
    if (respon.status_code === 200) {
      console.log(respon, "respon");
    } else console.log("save-fcm-token failed");
  };

  const dataSlide = [
    {
      id: 0,
      img: banner1,
    },
    {
      id: 1,
      img: banner2,
    },
    { id: 2, img: banner3 },
  ];

  return (
    <Box sx={{ paddingLeft: { xs: "15px" }, paddingRight: { xs: "15px" } }}>
      <Pages>
        <Carousel className="slide-homepage">
          {dataSlide.map((item: any, index: any) => {
            return (
              <>
                <Box
                  key={index}
                  component={"img"}
                  src={item.img}
                  sx={{
                    height: {
                      xs: "250px",
                      sm: "400px",
                      md: "600px",
                    },
                    cursor: "pointer",
                  }}
                />
                <Box
                  sx={{
                    position: "absolute",
                    bottom: { xs: "20%", sm: "32%" },
                    paddingLeft: "50px",
                    paddingRight: "50px",
                  }}
                  className="description">
                  <div className="slide-title">
                    <LargeText style={{ marginBottom: "10px" }}>
                      WELCOME TO TBT GROUPS
                    </LargeText>
                  </div>
                  <div className="slide-content">
                    <DefaultText>
                      We specialize in providing high-quality plants and offer a
                      wide variety of plant options to our customers.
                    </DefaultText>
                  </div>
                </Box>
              </>
            );
          })}
        </Carousel>
        <Box
          sx={{
            display: { xs: "none", sm: "flex" },
          }}
          className="product-homepage">
          <div
            className="plant"
            onClick={() => {
              dispatch({ type: TypesAction.CATEGORY_ID, payload: 1 });
              dispatch({ type: TypesAction.SHOW_INPUT_SEARCH, payload: true });
              navigate("/products-plants");
            }}>
            <div className="plant-tree">
              <Box
                component={"img"}
                src={plantTree}
                alt="plantTree"
                width={180}
                height={260}
                // sx={{
                //   width: { xs: "100px", md: "180px" } ,
                //   height: 'auto'
                // }}
              />
            </div>
            <div className="plant-logo">
              <Box
                className="plant-logo-img"
                component={"img"}
                src={plantLogo}
                alt="plant-logo-img"
                width={180}
                height={140}
                // sx={{
                //   width: { xs: "100px", md: "180px" } ,
                //   height: 'auto'
                // }}
              />
              <span className="plant-title">PLANTS</span>
            </div>
          </div>
          <div
            className="plastic-pots"
            onClick={() => {
              dispatch({ type: TypesAction.CATEGORY_ID, payload: 2 });
              dispatch({ type: TypesAction.SHOW_INPUT_SEARCH, payload: true });
              navigate("/products-pots");
            }}>
            <div className="plastic-items">
              <img
                className="pots"
                src={plasticItem}
                alt="plasticItem"
                width={180}
                height={140}
              />

              <img
                className="logo-pots"
                src={plasticLogo}
                alt=""
                width={180}
                height={140}
              />
            </div>
            <div className="plastic-title">PLASTIC POTS</div>
          </div>
        </Box>
        <Box
          className="product-homepage-mobile"
          sx={{
            display: { xs: "flex", sm: "none" },
          }}>
          <Box
            onClick={() => {
              dispatch({ type: TypesAction.CATEGORY_ID, payload: 1 });
              dispatch({ type: TypesAction.SHOW_INPUT_SEARCH, payload: true });
              navigate("/products-plants");
            }}
            component={"img"}
            src={plantMobile}
            width={"50%"}
            height={"auto"}
          />
          <Box
            onClick={() => {
              dispatch({ type: TypesAction.CATEGORY_ID, payload: 2 });
              dispatch({ type: TypesAction.SHOW_INPUT_SEARCH, payload: true });
              navigate("/products-pots");
            }}
            component={"img"}
            src={potMobile}
            width={"50%"}
            height={"auto"}
          />
        </Box>
      </Pages>
    </Box>
  );
};

export default memo(HomePage);
