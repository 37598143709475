import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { BtnPlaceYourOrder } from "../Cart.style";
import binImage from "../../../assets/Images/bin.png";
import { Paper } from "@mui/material";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "75%", sm: "50%", md: "35%" },
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 2,
  textAlign: "center",
};

interface ModalProps {
  title: string;
  content: string;
  isOpen: boolean;
  handleClose?: () => void;
  handleClear?: () => void;
}

export default function ModalConfirm(props: ModalProps) {
  const { title, content, isOpen, handleClose, handleClear } = props;

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Box sx={style}>
        <Box
          mb={5}
          component={"img"}
          src={binImage}
          style={{ width: 120, height: 120 }}
        />

        <Typography variant="h6" fontWeight={600}>
          {content}
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "#fff",
          }}
          className="bg_btn">
          <button onClick={handleClose} className="btn-cancel">
            Cancel
          </button>
          <button onClick={handleClear} className="btn-confirm">
            Confirm
          </button>
        </Box>
      </Box>
    </Modal>
  );
}
