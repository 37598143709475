import { Reducer } from "redux";
import States from "./state";
import { TypesAction } from "../typesAction";

const initialState: States = {
  tabAction: 1,
};

const HomeReducer: Reducer<States> = (state = initialState, action) => {
  switch (action.type) {
    case TypesAction.TAB_ACTION_ACTIVE:
      return {
        ...state,
        tabAction: action.payload,
      };

    default:
      return state;
  }
};
export default HomeReducer;
