import { Reducer } from "redux";
import States from "./state";
import { CartType } from "../../gobalType";
import { TypesAction } from "../typesAction";

const initialState: States = {
  carts: [],
};

const CartReducer: Reducer<States> = (state = initialState, action) => {
  let result = state.carts;
  switch (action.type) {
    case TypesAction.ADD_TO_CART:
      const input: CartType = action.payload;
      const existingItem = result.find(
        (item) => item.id === input.id && item.size_id === input.size_id
      );
      if (existingItem) {
        result.map((item: any) => {
          if (item.id === input.id && item.size_id === input.size_id) {
            item.quantity += input.quantity;
          }
        });
      } else {
        result = [...state.carts, input];
      }
      return {
        ...state,
        carts: result,
      };
    case TypesAction.REMOVE_PRODUCT:
      result = state.carts.filter((item, index) => index !== action.payload);
      return {
        ...state,
        carts: result,
      };
    case TypesAction.EDIT_QUANTITY:
      const { quantity, index } = action.payload;
      result.map((item, i) => {
        if (i == index) {
          item.quantity = Number(quantity);
        }
      });
      return {
        ...state,
        carts: result,
      };
    case TypesAction.RESET_CART:
      return {
        ...state,
        carts: [],
      };
    default:
      return state;
  }
};
export default CartReducer;
