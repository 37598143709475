import * as React from "react";
import { Box, CircularProgress, Paper, Typography } from "@mui/material";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/scss/alice-carousel.scss";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import "./style.scss";
import {
  BASE_URL,
  HttpMethod,
  PATH_IMAGE_URL,
} from "../../../../services/api.constant";
import { bestSellers } from "../../../../services/apiList";
import { Link } from "react-router-dom";
import { theme } from "../../../../utils/theme";
import { Product, ProductSizes } from "../../../../gobalType";
import { callHttpClientMethod } from "../../../../services/callHttpClient";
import { useDispatch } from "react-redux";
import { AddToCart } from "../../Cart.style";
import { useNavigate } from "react-router-dom";
import { formatMoneyData } from "../../../../utils/storage";
import { TypesAction } from "../../../../redux/typesAction";
import { toast } from "react-toastify";
import TitleText from "../../../../components/Text/TitleText";
interface IProps {
  changeQTY?: any;
}

const BestSallersCart = (props: IProps) => {
  const { changeQTY } = props;
  const navigate = useNavigate();
  const [dataBestSaler, setdataBestSaler] = React.useState<Product[]>([]);
  const [loading, setLoading] = React.useState<boolean>(true);
  const dispatch = useDispatch();
  const [rightBtnDisabled, setRightBtnDisabled] = React.useState<boolean>(true);

  const loadData = async () => {
    const response = await callHttpClientMethod(
      BASE_URL + bestSellers(),
      HttpMethod.Get,
      {},
      {}
    );
    setdataBestSaler(response);
    setLoading(false);
  };

  React.useEffect(() => {
    loadData();
  }, []);

  React.useEffect(() => {
    if (dataBestSaler.length > 4) {
      setRightBtnDisabled(false);
    }
  }, [dataBestSaler.length]);

  const carousel = React.useRef<any>();

  const responsive = {
    0: {
      items: 1.2,
    },
    768: {
      items: 2,
    },
    1024: {
      items: 3,
      itemsFit: "contain",
    },
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}>
        <CircularProgress color="success" />
      </Box>
    );
  }

  const gotoDetail = (item: Product) => {
    navigate(`/product-details/${item.id}`, {
      state: {
        product_id: item?.product_sizes?.[0]?.product_id,
        size_id: item?.product_sizes?.[0]?.id,
      },
    });
  };

  return (
    <div style={{ marginTop: "10px" }}>
      {dataBestSaler?.length > 0 ? (
        <AliceCarousel
          animationDuration={300}
          responsive={responsive}
          mouseTracking
          disableDotsControls
          ref={(el) => (carousel.current = el)}
          onSlideChanged={(e: any) => {
            if (e?.item >= dataBestSaler?.length - 4) {
              setRightBtnDisabled(true);
            } else {
              setRightBtnDisabled(false);
            }
          }}
          renderPrevButton={({ isDisabled }: any) => (
            <KeyboardArrowLeftIcon
              className={`btn__left ${isDisabled ? "disabled" : ""}`}
              sx={{
                fontSize: 30,
                display: {
                  sm: "block",
                  xs: "none",
                },
              }}
            />
          )}
          renderNextButton={() => (
            <ChevronRightIcon
              className={`btn__right ${rightBtnDisabled ? "disabled" : ""}`}
              sx={{
                fontSize: 30,
                display: {
                  sm: "block",
                  xs: "none",
                },
              }}
            />
          )}>
          {dataBestSaler?.map((item: Product, index: number) => {
            const imagePath = PATH_IMAGE_URL + item?.thumbnail?.[0]?.image_path;
            let productDf = item?.product_sizes?.[0];
            let priceArray: any = productDf?.user_group_prices;
            let priceItem = priceArray[0]?.price;
            return (
              <Box
                key={index}
                sx={{
                  gap: "5px",
                  flex: 1,
                  display: "flex",
                  backgroundColor: "#E4E5E9",
                }}
                mr={2}
                padding={1}>
                <Box
                  component={"img"}
                  src={imagePath}
                  style={{
                    width: 70,
                    height: 84,
                    cursor: "pointer",
                    objectFit: "cover",
                  }}
                  onClick={() => {
                    gotoDetail(item);
                  }}
                />
                <Box
                  sx={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                  }}>
                  <Box
                    sx={{
                      display: "flex",
                      gap: "5px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      gotoDetail(item);
                    }}>
                    <TitleText>{item.name}</TitleText>
                    <TitleText
                      style={{
                        color: "text.highlight",
                        flex: 1,
                        textAlign: "end",
                      }}>
                      {formatMoneyData(priceItem)}
                    </TitleText>
                  </Box>

                  <Box
                    textAlign={"right"}
                    sx={{
                      flex: 1,
                      justifyContent: "flex-end",
                      alignSelf: "end",
                      display: "flex",
                      flexDirection: "column",
                    }}
                    onClick={() => {
                      let product = {
                        ...item,
                        sizeName: productDf?.name,
                        size_id: productDf?.id,
                        product_name: item.name,
                        product_id: item.id,
                        quantity: 1,
                        price: priceItem || 0,
                        extractID: productDf?.id + "" + productDf?.id,
                        isCategory: item.category_id === 1 ? "pcs" : "plt",
                      };
                      dispatch({
                        type: TypesAction.ADD_TO_CART,
                        payload: product,
                      });
                      toast.success("Added product to cart successfully");
                      changeQTY();
                    }}>
                    <AddToCart>Add to cart</AddToCart>
                  </Box>
                </Box>
              </Box>
            );
          })}
        </AliceCarousel>
      ) : null}
    </div>
  );
};

export default BestSallersCart;
