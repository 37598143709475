const loadState = () => {
  try {
    const serializedState: any = localStorage.getItem("state");
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

const saveState = (state: any) => {
  try {
    const serializedState = JSON.stringify(state);

    localStorage.setItem("state", serializedState);
  } catch {
    //
  }
};

function formatMoneyData(
  amount: number,
  currencySymbol = "$",
  thousandsSeparator = ","
) {
  const roundedAmount = Math.round(amount * 100) / 100;

  const amountString = roundedAmount.toFixed(2);

  const [integerPart] = amountString.split(".");

  const integerWithThousands = integerPart.replace(
    /\B(?=(\d{3})+(?!\d))/g,
    thousandsSeparator
  );

  return currencySymbol + integerWithThousands;
}

function convertToNumber(value: string) {
  value = value.replace(/[^0-9]/g, "");
  let result = Number(value);
  return result;
}

function capitalizeWords(str: string) {
  // Chia chuỗi thành mảng các từ
  let words = str.split(" ");

  // Lặp qua mỗi từ và định dạng lại
  for (let i = 0; i < words.length; i++) {
    let word = words[i];
    // Chuyển chữ cái đầu tiên của từ thành chữ in hoa
    // và các chữ cái còn lại thành chữ thường
    words[i] = word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  }
  return words.join(" ");
}

export {
  loadState,
  saveState,
  formatMoneyData,
  convertToNumber,
  capitalizeWords,
};
