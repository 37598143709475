import React, { memo } from "react";
import Seo, { SeoProps } from "../components/Header/common/Seo";
import { Box } from "@mui/material";

type PagesProps = {
  seo?: SeoProps;
  children?: React.ReactNode;
};

const Pages: React.FC<PagesProps> = ({ seo, children }) => {
  return (
    <Box className="page page__wrapper" id="back-to-top-anchor">
      <Seo {...seo} />
      {children}
    </Box>
  );
};

export default memo(Pages);
