import { Reducer } from "redux";
import States from "./state";
import { TypesAction } from "../typesAction";

const initialState: States = {
  loading: false,
  order:[],
  error:null
};

const OrderReducer: Reducer<States> = (state = initialState, action) => {
  switch (action.type) {
    case TypesAction.GET_ORDERS:
      return {
        ...state,
        order: action.payload,
      };

    default:
      return state;
  }
};
export default OrderReducer;
