import {
  Box,
  Button,
  CircularProgress,
  Input,
  Typography,
  Checkbox,
  Menu,
  MenuItem,
} from "@mui/material";
import "./style.scss";

import { ButtonNoti } from "../../components/Header/header.style";
import { memo, useEffect, useMemo, useState } from "react";
import { callHttpClientMethod } from "../../services/callHttpClient";
import { BASE_URL, HttpMethod } from "../../services/api.constant";
import {
  deleteAll,
  deleteNoti,
  notiLoadMore,
  notification,
  readAllNoti,
  seenAll,
} from "../../services/apiList";

import { ArrNotification } from "../../gobalType";
import { useLocation, useNavigate } from "react-router";
import { RootState } from "../../redux";
import { useDispatch, useSelector } from "react-redux";
import { TypesAction } from "../../redux/typesAction";
import ItemNoti from "./components/ItemNoti";
import ModalConfirm from "../Cart/components/ModalConfirm";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import BasicModal from "../Cart/components/Modal";
function ListNotification() {
  const { listNotification, loadingNoti, current_page, last_page } =
    useSelector((state: RootState) => state.listNotification);
  const { auth } = useSelector((state: RootState) => state.auth);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [arrchoice, setArrchoice] = useState<any>([]);
  const [path, setPath] = useState("");
  console.log(path, "path");

  const [loading, setLoading] = useState(false);
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const [anchorOptions, setAnchorOptions] = useState(null);
  const [stateRead, setStateRead] = useState(false);
  const [showOrder, setShowOrder] = useState(false);

  const options = [
    {
      path: "mark-noti",
      title: "Mark all as read",
      icon: <CheckIcon sx={{ width: { xs: 20, sm: 27 } }} />,
    },
    {
      path: "clear-all",
      title: "Clear All",
      icon: <ClearIcon sx={{ width: { xs: 20, sm: 27 } }} />,
    },
  ];

  const handleOpenOptions = (e: any) => {
    setAnchorOptions(e.currentTarget);
  };
  const handleCloseOptions = () => {
    setAnchorOptions(null);
  };

  const handleChoice = (id: any) => {
    var index = arrchoice.indexOf(id);
    // Nếu phần tử tồn tại trong mảng, xoá nó
    if (index !== -1) {
      var arrNew = arrchoice.filter(function (item: any) {
        return item !== id;
      });
      setArrchoice(arrNew);
    } else {
      setArrchoice(arrchoice.concat(id));
    }
  };

  const handleChoiceAll = () => {
    if (arrchoice?.length === listNotification?.length) {
      setArrchoice([]);
    } else {
      var arrNew1: any = listNotification?.map((item: any) => {
        return item.id;
      });
      setArrchoice(arrNew1);
    }
  };

  const handleUnChoiceAll = () => {
    setArrchoice([]);
  };
  const getNotify = async () => {
    console.log("getNoti");
    dispatch({
      type: TypesAction.GET_LIST_NOTIFICATION,
      payload: {
        dataNoti: [],
        current_page: 0,
        last_page: 0,
        loadingNoti: true,
      },
    });

    const res = await callHttpClientMethod(
      BASE_URL + notification,
      HttpMethod.Get,
      {},
      {
        headers: {
          Authorization: `Bearer ${auth?.access_token}`,
        },
      }
    );
    if (res.status_code === 200) {
      dispatch({
        type: TypesAction.GET_LIST_NOTIFICATION,
        payload: {
          dataNoti: res?.data?.data,
          current_page: res?.data.current_page,
          last_page: res?.data.last_page,
          loadingNoti: false,
        },
      });
    }
  };
  useEffect(() => {
    if (auth?.access_token) {
      getNotify();
    }
  }, [auth?.access_token]);

  const handleDeleteNoti = async () => {
    setLoading(true);
    const dataIds = {
      ids: arrchoice,
    };

    const respon = await callHttpClientMethod(
      BASE_URL + deleteNoti(),
      HttpMethod.Post,
      dataIds,
      {
        headers: {
          Authorization: `Bearer ${auth?.access_token}`,
        },
      }
    );

    if (respon.status_code === 200) {
      setLoading(false);
      setShowOrder(true);
      dispatch({ type: TypesAction?.DELETE_IDS, payload: arrchoice });
      setOpenConfirm(false);
      handleUnChoiceAll();
    }
  };
  const handleDeleteAll = async () => {
    setLoading(true);

    var arrChoiceAll: any = listNotification?.map((item: any) => {
      return item.id;
    });

    const dataIds = {
      ids: arrChoiceAll,
    };

    const respon = await callHttpClientMethod(
      BASE_URL + deleteAll(),
      HttpMethod.Post,
      dataIds,
      {
        headers: {
          Authorization: `Bearer ${auth?.access_token}`,
        },
      }
    );

    if (respon.status_code === 200) {
      setShowOrder(true);
      setLoading(false);
      dispatch({ type: TypesAction?.DELETE_IDS, payload: arrChoiceAll });
      setOpenConfirm(false);
      handleCloseOptions();
    } else {
      handleCloseOptions();
    }
  };
  const handleNextPage = async () => {
    setLoading(true);

    const res = await callHttpClientMethod(
      BASE_URL + notiLoadMore(current_page + 1),

      HttpMethod.Get,
      {},
      {
        headers: {
          Authorization: `Bearer ${auth?.access_token}`,
        },
      }
    );
    if (res.status_code === 200) {
      setLoading(false);
      dispatch({
        type: TypesAction.GET_LIST_NOTIFICATION,
        payload: {
          dataNoti: listNotification.concat(res?.data?.data),
          current_page: res?.data.current_page,
          last_page: res?.data.last_page,
        },
      });
    }
  };

  const handleReadAllNoti = async () => {
    const respon = await callHttpClientMethod(
      BASE_URL + readAllNoti(),
      HttpMethod.Post,
      {},
      {
        headers: {
          Authorization: `Bearer ${auth?.access_token}`,
        },
      }
    );
    if (respon.status_code === 200) {
      handleCloseOptions();
      getNotify();
      setStateRead(true);
    } else handleCloseOptions();
  };

  if (loadingNoti || loading) {
    return (
      <Box
        sx={{
          margin: { xs: 15, sm: 32 },
          display: "flex",
          justifyContent: "center",
        }}>
        <CircularProgress color="success" />
      </Box>
    );
  }

  return (
    <Box
      maxWidth={"sm"}
      sx={{
        margin: "auto",
        marginBottom: 3,
        marginTop: { xs: -7, sm: -5 },
        backgroundColor: "#fff",
        padding: { xs: "20px", sm: "30px" },
        borderRadius: { xs: "none", sm: 5 },
        boxShadow: { xs: "none", sm: 7 },
      }}>
      <Box>
        <Typography
          sx={{
            fontWeight: "600",
            textAlign: "center",
            marginTop: "10px",
            fontSize: { xs: 18, sm: 25 },
          }}>
          Notifications
        </Typography>

        {listNotification?.length > 0 ? (
          <Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}>
                  <Checkbox
                    checked={arrchoice?.length === listNotification?.length}
                    onClick={handleChoiceAll}
                    sx={{
                      "& .MuiSvgIcon-root": {
                        fontSize: { xs: 18, sm: 23 },
                        borderColor: "#333",
                      },
                    }}
                  />
                  <Typography
                    sx={{
                      marginBottom: 0,
                      fontSize: { xs: 12, sm: 16 },
                      marginLeft: "-2px",
                    }}>
                    Select all
                  </Typography>
                </Box>
                <Box
                  onClick={() => setOpenConfirm(true)}
                  sx={{
                    display: arrchoice?.length > 0 ? "flex" : "none",
                    marginLeft: 1,
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                  }}>
                  <DeleteOutlineOutlinedIcon
                    className="icon-delete"
                    sx={{ color: "rgba(0, 0, 0, 0.6)" }}
                  />
                  <Typography
                    sx={{
                      marginBottom: 0,
                      fontSize: { xs: 12, sm: 16 },
                      marginLeft: "-5px",
                    }}>
                    Clear
                  </Typography>
                </Box>
              </Box>
              <ButtonNoti onClick={(e) => handleOpenOptions(e)} id="fs-noti">
                <MoreHorizIcon />
              </ButtonNoti>
              <Menu
                sx={{ mt: "35px" }}
                id="menu-appbar"
                anchorEl={anchorOptions}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorOptions)}
                onClose={handleCloseOptions}>
                {options?.map((option, index) => (
                  <MenuItem
                    sx={{
                      marginBottom: 0,
                      padding: {
                        xs: "0 10px",
                        sm: "10px 20px 10px 10px",
                      },
                    }}
                    key={index}
                    onClick={() => {
                      if (option?.path === "mark-noti" && !stateRead) {
                        handleReadAllNoti();
                      } else if (option?.path === "clear-all") {
                        handleCloseOptions();
                        setOpenConfirm(true);
                        setPath(option?.path);
                      } else handleCloseOptions();
                    }}>
                    <Typography
                      sx={{
                        marginBottom: 0,
                        paddingRight: 1,
                      }}>
                      {option?.icon}
                    </Typography>
                    <Typography
                      sx={{ marginBottom: 0, fontSize: { xs: 12, sm: 16 } }}
                      textAlign="center">
                      {option?.title}
                    </Typography>
                  </MenuItem>
                ))}
              </Menu>
              <ModalConfirm
                isOpen={openConfirm}
                handleClose={() => setOpenConfirm(false)}
                title={"Confirm"}
                content={`You definitely want to delete notifications `}
                handleClear={() => {
                  if (path === "clear-all") {
                    handleDeleteAll();
                  } else handleDeleteNoti();
                }}
              />
              <BasicModal
                isOpen={showOrder}
                handleClose={() => setShowOrder(false)}
                title={""}
                content={`You have successfully deleted notifications.`}
              />
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mt: 1,
                marginBottom: -2,
              }}>
              <Typography id="fs-noti" sx={{ marginLeft: 2, fontWeight: 600 }}>
                Earlier
              </Typography>
            </Box>
            {listNotification?.map((item: ArrNotification, index: any) => {
              return (
                <ItemNoti
                  data={item}
                  isChoice={arrchoice.includes(item?.id)}
                  onChoice={() => handleChoice(item?.id)}
                />
              );
            })}
          </Box>
        ) : (
          <Box
            id="fs-noti"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "20px",
              marginBottom: "20px",
            }}>
            You have no notifications
          </Box>
        )}
        <Button
          onClick={() => handleNextPage()}
          sx={{
            width: "100%",
            textTransform: "capitalize",

            display:
              current_page === last_page || listNotification?.length === 0
                ? "none"
                : "block",
          }}>
          <Typography
            sx={{
              paddingTop: "5px",
              paddingBottom: "5px",
              marginBottom: 0,
              fontSize: { xs: 12, sm: 16 },
            }}>
            Load more
          </Typography>
        </Button>
      </Box>
    </Box>
  );
}

export default memo(ListNotification);
