import { initializeApp } from "firebase/app";
import { useState } from "react";
const firebaseConfig = {
  apiKey: "AIzaSyBs_yBEKPd6LQq5WLoaepop7V80W5yjsL0",
  authDomain: "tbtgroup2023-930b4.firebaseapp.com",
  databaseURL: "https://tbtgroup2023-930b4-default-rtdb.firebaseio.com",
  projectId: "tbtgroup2023-930b4",
  storageBucket: "tbtgroup2023-930b4.appspot.com",
  messagingSenderId: "431183440249",
  appId: "1:431183440249:web:6d5a1ecadb56d5f724a7a8",
  measurementId: "G-CECK2YH36M",
};

const firebaseAppTBT = initializeApp(firebaseConfig);

const requestPermission = () => {
  console.log("=================THỰC HIỆN REQUEST===========================");
  Notification.requestPermission().then((permission) => {
    if (permission === "granted") {
      console.log("Notification User Permission Granted.");
      return permission;
    } else {
      console.log("User Permission Denied.");
    }
  });
};

export { firebaseAppTBT, requestPermission };
