import styled from "@emotion/styled";
import { theme } from "../../utils/theme";
import { TableCell } from "@mui/material";

export const Table = styled.table`
  width: 100%;
`;

export const TableRow = styled.tr``;

export const Th = styled(TableCell)`
  padding: 15px;
  border-bottom: 1px solid #dadada;
  text-align: center;
`;
export const Td = styled(TableCell)`
  border-bottom: 1px solid #dadada;
  padding: 0;
`;

export const Label = styled.label`
  font-weight: normal;
  color: ${theme.palette.text.primary};
`;
export const Textaea = styled.textarea`
  width: 100%;
  border-radius: 10px;
  border: 1px solid #c4c4c4;
  padding: 5px 10px;
  color: ${theme.palette.text.primary};
`;

export const BestSellers = styled.div`
  border-top: 1px solid #979797;
  border-bottom: 1px solid #979797;
  margin-bottom: 20px;
`;
export const AddToCart = styled.button`
  border-radius: 25px;
  border: 1px solid rgba(135, 135, 135, 0.5);
  padding: 5px 15px;
  font-size: 10px;
  text-transform: uppercase;
  color: ${theme.palette.text.primary};
  background-color: ${theme.palette.background.default};
  cursor: pointer;
  &:hover {
    background-color: #007f18;
    color: #fff;
  }
`;

export const BtnPlaceYourOrder = styled.button`
  font-size: 20px;
  text-transform: uppercase;
  border-radius: 25px;
  border: 1px solid rgba(135, 135, 135, 0.5);
  background: #e4e5e9;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  color: ${theme.palette.text.primary};
  padding: 15px 40px;
`;
