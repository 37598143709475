import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import { theme } from "../../utils/theme";

export const MenuLink = styled(Link)`
  text-decoration: none;
  color: ${theme.palette.text.body};
  font-size: 15px;
  font-weight: 700;
  &:hover {
    color: ${theme.palette.common.green};
    text-decoration: none;
  }
`;

export const ButtonLogin = styled(Link)`
  text-decoration: none;
  border-radius: 20px;
  border: 1px solid rgba(135, 135, 135, 0.5);
  background: #e4e5e9;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  font-size: 15px;
  font-weight: 700;
  padding: 10px 24px;
  color: ${theme.palette.text.body};
`;
export const ButtonNoti = styled.button`
  border: none;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
  color: ${theme.palette.common.black};
  cursor: pointer;
  border-radius: 100%;
  &:hover {
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    background-color: #e4e5e9;
  }
`;
