import React, { memo } from "react";
import { Grid, GridProps, useTheme } from "@mui/material";

const Col: React.FC<GridProps> = ({ children, item = true, sx, ...props }) => {
  const theme = useTheme();
  return (
    <Grid
      item
      sx={{
        px: theme.breakpoints.spacing,
        ...sx,
      }}
      {...props}
    >
      {children}
    </Grid>
  );
};

export default memo(Col);
