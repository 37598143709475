// Import các thư viện và types cần thiết
import { combineReducers } from "redux";
import { connectRouter, RouterState } from "connected-react-router";
import { History } from "history";

// Import các reducers và states của ứng dụng
import ProductsReducer from "./product/reducer";
import ProductState from "./product/state";
import HomeState from "./home/state";
import HomeReducer from "./home/reducer";
import AuthState from "./auth/state";
import AuthReducer from "./auth/reducer";
import CartState from "./cart/state";
import CartReducer from "./cart/reducer";
import OrderState from "./order/state";
import OrderReducer from "./order/reducer";
import ListNotiReducer from "./listNotification/reducer";
import ListNotificationState from "./listNotification/state";

// Định nghĩa các trạng thái của store
export interface RootState {
  router: RouterState; // Trạng thái của router
  products: ProductState;
  home: HomeState;
  auth: AuthState;
  cart: CartState;
  order: OrderState;
  listNotification: ListNotificationState;
}

// Hàm rootReducer nhận vào đối tượng history và trả về rootReducer của ứng dụng
const rootReducer = (history: History) =>
  combineReducers({
    // Kết hợp các reducers vào một rootReducer
    router: connectRouter(history), // Kết nối reducer router với history
    products: ProductsReducer,
    home: HomeReducer,
    auth: AuthReducer,
    cart: CartReducer,
    order: OrderReducer,
    listNotification: ListNotiReducer,
  });

export default rootReducer;
