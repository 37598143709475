import {
  Box,
  Button,
  FormControl,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import { theme } from "../../../utils/theme";
import { useEffect, useState } from "react";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import axios from "axios";
import {
  BASE_URL,
  HttpMethod,
  PATH_IMAGE_URL,
} from "../../../services/api.constant";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import {
  updateCertificate,
  updateUser,
  userInfo,
} from "../../../services/apiList";
import { callHttpClientMethod } from "../../../services/callHttpClient";
import { TypesAction } from "../../../redux/typesAction";
import { RootState } from "../../../redux";
import LoadingButton from "@mui/lab/LoadingButton";
import ClearIcon from "@mui/icons-material/Clear";
import SaveIcon from "@mui/icons-material/Save";
import "../style.scss";
interface ModalProps {
  isOpen: boolean;
  handleClose: () => void;
  getUserInfo: () => void;
}
function UpdateUser(props: ModalProps) {
  const { isOpen, handleClose, getUserInfo } = props;
  const { user, auth } = useSelector((state: RootState) => state.auth);
  const [imgCetificate, setImageCetificate] = useState<string>("");
  const [email, setEmail] = useState(user?.email);

  const [name, setName] = useState(user?.full_name);
  const [phoneNumber, setPhoneNumber] = useState(user?.phone_number);
  const [address, setAddress] = useState(user?.address);

  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState("");
  const dispatch = useDispatch();

  const handleUpdateCertificate = async () => {
    setLoading(true);
    const formData = new FormData();
    formData.append("certificate", imgCetificate);

    await axios({
      method: "post",
      url: `${BASE_URL + updateCertificate()}`,
      data: formData,

      headers: {
        Authorization: `Bearer ${auth?.access_token}`,
      },
    })
      .then(function (response) {
        toast.success("Update certificate successfully.");
        getUserInfo();

        setLoading(false);
        handleClose();
      })
      .catch(function (error) {
        setLoading(false);
        toast.error("Update certificate failed!");
      });
  };
  const handlePreviewCetificate = (e: any) => {
    setImageCetificate(e.target.files[0]);
    console.log(e.target.files[0], "check dữ liệu khi chọn ảnh");

    const fileURL = URL.createObjectURL(e.target.files[0]);
    fileURL && setFile(fileURL);
  };

  const handleUpdateUser = async () => {
    const dataEdit = {
      full_name: name,
      email: email,
      phone_number: phoneNumber,
      address: address,
    };

    dispatch({ type: TypesAction.USERINFO, payload: { loadingUser: true } });
    const responsive = await callHttpClientMethod(
      BASE_URL + updateUser(),
      HttpMethod.Post,
      dataEdit,
      {
        headers: {
          Authorization: `Bearer ${auth?.access_token}`,
        },
      }
    );
    if (responsive.status_code === 200) {
      toast.success("Your personal information has been successfully updated!");
      getUserInfo();
      handleClose();
    } else {
      toast.error("Updating personal information failed!");
    }
  };
  const responInput = { width: { xs: "100%", sm: "80%" } };
  const style = {
    width: { xs: "75%", sm: "50%" },
    position: "absolute" as "absolute",
    top: "55%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    padding: 20,
    boxShadow: 24,
    borderRadius: "5px",
    outLine: "none",
    p: 4,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    overflowY: "scroll",
    maxHeight: 510,
  };

  return (
    <Modal
      sx={{ borderColor: "none" }}
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Box sx={style}>
        <Box className={"header-edit"}>
          <Typography sx={{ textAlign: "center", fontWeight: 600 }}>
            EDIT ACCOUNT INFO
          </Typography>
          <Box
            onClick={handleClose}
            sx={{ position: "absolute", right: 4, top: 4 }}>
            <ClearIcon />
          </Box>
        </Box>

        <FormControl sx={responInput}>
          <TextField
            onChange={(e) => setName(e.target.value)}
            defaultValue={name}
            placeholder="Enter fullname"></TextField>
        </FormControl>
        <FormControl sx={responInput}>
          <TextField
            disabled
            onChange={(e) => setEmail(e.target.value)}
            defaultValue={email}
            placeholder="Enter email"></TextField>
        </FormControl>
        <FormControl sx={responInput}>
          <TextField
            inputMode="numeric"
            onChange={(e) => setPhoneNumber(e.target.value)}
            defaultValue={phoneNumber}
            placeholder="Enter phone number"></TextField>
        </FormControl>
        <FormControl onSubmit={handleUpdateUser} sx={responInput}>
          <TextField
            id="enter_edit"
            onChange={(e) => setAddress(e.target.value)}
            defaultValue={address}
            placeholder="Enter address"></TextField>
        </FormControl>
        <Box>
          <input
            accept="image/*"
            name="cetificate"
            style={{ display: "none" }}
            type="file"
            id="upload-cetificate"
            onChange={(e) => {
              handlePreviewCetificate(e);
            }}
          />

          <Button
            sx={{
              display: "flex",
              margin: "auto",
              textTransform: "capitalize",
              borderRadius: 10,
              width: { xs: 180, sm: 200 },

              backgroundColor: "#e4e5e9",
              color: `${theme.palette.text.body}`,
              ":hover": { backgroundColor: "#e4e5e9" },
              boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
            }}>
            <label htmlFor="upload-cetificate" style={{ cursor: "pointer" }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  margin: "auto",
                }}>
                <CloudUploadIcon
                  sx={{ color: `${theme.palette.common.green}`, mr: "5px" }}
                />
                <Typography
                  sx={{ marginBottom: 0, fontSize: { xs: 14, sm: 16 } }}>
                  Resale Certificate
                </Typography>
              </Box>
            </label>
          </Button>
          <Box sx={{ margin: "25px 0" }}>
            {(imgCetificate || user?.certificate) && (
              <Box
                component={"img"}
                src={file || `${PATH_IMAGE_URL + user?.certificate}`}
                sx={{
                  marginBottom: "25px",
                  display: "flex",
                  margin: "auto",
                  alignItems: "center",
                  width: "100%",
                  maxHeight: "400px",
                  objectFit: "fill",
                  borderRadius: "10px",
                }}
              />
            )}
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}>
          {!loading ? (
            <Button
              sx={{
                paddingLeft: 4,
                paddingRight: 4,
                borderRadius: 10,
                width: 200,
                margin: "auto",
                backgroundColor: "#e4e5e9",
                color: `${theme.palette.text.body}`,
                ":hover": { backgroundColor: "#e4e5e9" },
              }}
              variant="contained"
              color="success"
              type="submit"
              onClick={() => {
                if (
                  user?.full_name !== name ||
                  user?.phone_number !== phoneNumber ||
                  user?.address !== address
                )
                  handleUpdateUser();
                else handleClose();
                imgCetificate && handleUpdateCertificate();
              }}>
              Save
            </Button>
          ) : (
            <LoadingButton
              sx={{
                paddingLeft: 4,
                paddingRight: 4,
                width: 200,
                borderRadius: 10,
              }}
              loading
              loadingPosition="start"
              startIcon={<SaveIcon />}
              variant="outlined">
              <span>Save</span>
            </LoadingButton>
          )}
        </Box>
      </Box>
    </Modal>
  );
}

export default UpdateUser;
