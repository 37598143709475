import { Box, Checkbox, Menu, MenuItem, Typography } from "@mui/material";

import { useDispatch } from "react-redux";

import { useEffect, useState } from "react";

import { theme } from "../../../utils/theme";

import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
interface IProps {
  all_size: any;
  categories: any;
  arrchoiceSize: any;
  arrchoicePlant: any;
  handleChoiceAll: (arr: any, type: any) => void;
  handleChoicePlant: (cate: any) => void;
  handleChoiceSize: (size: any) => void;
}
function FilterInMobile(props: IProps) {
  const {
    all_size,
    arrchoiceSize,
    arrchoicePlant,
    categories,
    handleChoiceAll,
    handleChoicePlant,
    handleChoiceSize,
  } = props;

  const [valueDropDownPlant, setValueDropDownPlant] = useState("All Plants");
  const [valueDropDownSize, setValueDropDownSize] = useState("All Sizes");

  const [anchorOptions, setAnchorOptions] = useState(null);
  const [anchorOptionsSize, setAnchorOptionsSize] = useState(null);
  const dispatch = useDispatch();
  const handleOpenOptionsSize = (e: any) => {
    setAnchorOptionsSize(e.currentTarget);
  };
  const handleCloseOptionsSize = () => {
    setAnchorOptionsSize(null);
  };
  const handleOpenOptions = (e: any) => {
    setAnchorOptions(e.currentTarget);
  };
  const handleCloseOptions = () => {
    setAnchorOptions(null);
  };
  const styleButton = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "70px",
    backgroundColor: `${theme.palette.dark.dark}`,
    color: "#4E4747",
    border: "1px solid #979797",
    borderRadius: "5px",
    padding: "5px",
  };

  // let result;
  useEffect(() => {
    categories?.map((item: any) => {
      if (item.id === arrchoicePlant[arrchoicePlant.length - 1]) {
        setValueDropDownPlant(item.name);
      } else if (arrchoicePlant.length === 0) {
        setValueDropDownPlant("All Plants");
      }
    });
  }, [arrchoicePlant]);
  useEffect(() => {
    all_size?.map((item: any) => {
      if (item.value === arrchoiceSize[arrchoiceSize.length - 1]) {
        setValueDropDownSize(item.name);
      } else if (arrchoiceSize.length === 0) {
        setValueDropDownSize("All Sizes");
      }
    });
  }, [arrchoiceSize]);
  return (
    <Box
      sx={{
        display: { xs: "block", sm: "none" },
        marginLeft: "15px",
        mb: "10px",
      }}>
      <Typography sx={{ fontWeight: "600", marginBottom: 0, fontSize: 16 }}>
        Filter by
      </Typography>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}>
          <Typography
            sx={{
              marginBottom: 0,
              color: "#4E4747",
              marginRight: 2,
              fontSize: { xs: 13, sm: 16 },
            }}>
            Categories
          </Typography>
          <Box onClick={handleOpenOptions} sx={styleButton}>
            <Typography
              sx={{
                color: "#898A8D",
                marginBottom: 0,
                mr: "4px",
                fontSize: { xs: 12, sm: 16 },
              }}>
              {valueDropDownPlant}
            </Typography>
            {anchorOptions === null ? (
              <KeyboardArrowDownOutlinedIcon
                sx={{ fontSize: { xs: 15, sm: 18 }, color: "#4E4747" }}
              />
            ) : (
              <KeyboardArrowUpOutlinedIcon
                sx={{ fontSize: { xs: 15, sm: 18 }, color: "#4E4747" }}
              />
            )}
          </Box>
        </Box>

        <Menu
          sx={{ mt: "35px" }}
          id="menu-appbar"
          anchorEl={anchorOptions}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(anchorOptions)}
          onClose={handleCloseOptions}>
          {categories?.map((option: any, index: any) => (
            <Box
              sx={{
                cursor: "pointer",
                display: "flex",
                backgroundColor:
                  arrchoicePlant.includes(option?.id) ||
                  arrchoicePlant.length === categories.length - 1
                    ? "#E7F3E7"
                    : "#fff",
              }}>
              <MenuItem
                sx={{
                  minWidth: "150px",
                  marginBottom: 0,
                  padding: {
                    xs: "0 10px",
                    sm: "10px 20px 10px 10px",
                  },
                }}
                onClick={() => {
                  option.id === 0
                    ? handleChoiceAll(arrchoicePlant, "plant")
                    : handleChoicePlant(option);
                }}
                key={index}>
                <Checkbox
                  checked={
                    arrchoicePlant.includes(option?.id) ||
                    arrchoicePlant.length === categories.length - 1
                  }
                  sx={{
                    "& .MuiSvgIcon-root": {
                      fontSize: { xs: 18, sm: 23 },
                      color:
                        arrchoicePlant.includes(option?.id) ||
                        arrchoicePlant.length === categories.length - 1
                          ? `${theme.palette.common.green}`
                          : "#979797",
                    },
                  }}
                />
                <Typography
                  sx={{
                    marginBottom: 0,
                    fontSize: 12,
                    color:
                      arrchoicePlant.includes(option?.id) ||
                      arrchoicePlant.length === categories.length - 1
                        ? " #263238"
                        : " #979797",
                  }}
                  textAlign="center">
                  {option?.name}
                </Typography>
              </MenuItem>
            </Box>
          ))}
        </Menu>

        <Box sx={{ display: "flex", alignItems: "center", mr: 2 }}>
          <Typography
            sx={{
              marginBottom: 0,
              color: "#4E4747",
              marginRight: 2,
              fontSize: { xs: 13, sm: 16 },
            }}>
            Size
          </Typography>
          <Box onClick={handleOpenOptionsSize} sx={styleButton}>
            <Typography
              sx={{
                color: "#898A8D",
                marginBottom: 0,
                mr: "4px",
                fontSize: { xs: 12, sm: 16 },
              }}>
              {valueDropDownSize}
            </Typography>
            {anchorOptionsSize === null ? (
              <KeyboardArrowDownOutlinedIcon
                sx={{ fontSize: { xs: 15, sm: 18 }, color: "#4E4747" }}
              />
            ) : (
              <KeyboardArrowUpOutlinedIcon
                sx={{ fontSize: { xs: 15, sm: 18 }, color: "#4E4747" }}
              />
            )}
          </Box>
        </Box>

        <Menu
          sx={{ mt: "35px" }}
          id="menu-appbar"
          anchorEl={anchorOptionsSize}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(anchorOptionsSize)}
          onClose={handleCloseOptionsSize}>
          {all_size?.map((option: any, index: any) => (
            <Box
              sx={{
                cursor: "pointer",
                display: "flex",
                backgroundColor:
                  arrchoiceSize.includes(option?.value) ||
                  arrchoiceSize?.length === all_size?.length - 1
                    ? "#E7F3E7"
                    : "#fff",
              }}>
              <MenuItem
                sx={{
                  minWidth: "150px",
                  marginBottom: 0,
                  padding: {
                    xs: "0 10px",
                    sm: "10px 20px 10px 10px",
                  },
                  backgroundColor: arrchoiceSize.includes(option?.value)
                    ? "#E7F3E7"
                    : "#fff",
                }}
                onClick={() => {
                  option.value === "all"
                    ? handleChoiceAll(arrchoiceSize, "size")
                    : handleChoiceSize(option);
                }}
                key={index}>
                <Checkbox
                  checked={
                    arrchoiceSize.includes(option?.value) ||
                    arrchoiceSize?.length === all_size?.length - 1
                  }
                  sx={{
                    "& .MuiSvgIcon-root": {
                      fontSize: { xs: 18, sm: 23 },
                      color:
                        arrchoiceSize.includes(option?.value) ||
                        arrchoiceSize?.length === all_size?.length - 1
                          ? `${theme.palette.common.green}`
                          : "#979797",
                    },
                  }}
                />
                <Typography
                  sx={{
                    marginBottom: 0,
                    fontSize: 12,
                    color:
                      arrchoiceSize.includes(option?.value) ||
                      arrchoiceSize.length === all_size.length - 1
                        ? " #263238"
                        : " #979797",
                  }}
                  textAlign="center">
                  {option?.name}
                </Typography>
              </MenuItem>
            </Box>
          ))}
        </Menu>
      </Box>
    </Box>
  );
}

export default FilterInMobile;
