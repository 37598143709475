import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ScrollToArrow from "../ScrollToArrow";
import phone from "./assets/Rectangle 46.png";
import mapQr from "./assets/QR_TBT.png";
import Container from "../Header/common/Container";
import Row from "../Header/common/Row";
import Col from "../Header/common/Col";
import { Link, useNavigate } from "react-router-dom";

const Footer = () => {
  const styleRes = {
    fontSize: {
      xs: "13px",
      sm: "16px",
    },
  };
  const navigate = useNavigate();
  return (
    <Box
      maxWidth="xs"
      component="footer"
      sx={{
        bgcolor: "background.footer",
      }}>
      <Container sx={{ paddingTop: "30px" }}>
        <Row>
          <Col lg={3}>
            <Box>
              <Typography style={{ fontWeight: "bold" }}>CALL US</Typography>
              <Link
                to="#1"
                onClick={(e) => {
                  window.location.href = "tel:714-425-9975";
                }}
                style={{ textDecoration: "none" }}>
                <Typography
                  sx={{
                    marginBottom: 0,
                    fontSize: { xs: "13px", sm: "16px" },
                  }}>
                  714-425-9975
                </Typography>
              </Link>
              <Link
                to="#2"
                onClick={(e) => {
                  window.location.href = "tel:714-673-5386";
                }}
                style={{ textDecoration: "none" }}>
                <Typography
                  sx={{
                    marginBottom: 0,
                    fontSize: { xs: "13px", sm: "16px" },
                  }}>
                  714-673-5386
                </Typography>
              </Link>
            </Box>

            <Box>
              <Typography
                variant="h6"
                fontWeight="bold"
                marginTop="10px"
                style={{ marginBottom: 0 }}>
                EMAIL US
              </Typography>
              <Link
                to="#3"
                onClick={(e) => {
                  window.location.href = "mailto:Sales@tbtgroups.com";
                }}
                style={{ textDecoration: "none" }}>
                <Typography sx={{ fontSize: { xs: "13px", sm: "16px" } }}>
                  Sales@tbtgroups.com
                </Typography>
              </Link>
            </Box>
          </Col>
          <Col lg={5}>
            <Box>
              <Typography variant="h6" fontWeight="bold">
                LOCATION
              </Typography>
              <Typography sx={{ fontSize: { xs: "13px", sm: "16px" } }}>
                4605 Ondich Rd. <br /> Apopka, FL 32712.
              </Typography>
            </Box>

            <Box>
              <Typography variant="h6" fontWeight="bold" marginTop="10px">
                WORKING HOURS
              </Typography>
              <table>
                <tr>
                  <Box component="td" sx={styleRes}>
                    Mon-Sat
                  </Box>
                  <Box component="td" sx={styleRes}>
                    :
                  </Box>
                  <Box
                    component="td"
                    sx={{ pl: "15px", fontSize: { xs: "13px", sm: "16px" } }}>
                    8:00am - 5:00pm
                  </Box>
                </tr>
                <tr>
                  <Box component="td" sx={styleRes}>
                    Sun
                  </Box>
                  <Box component="td" sx={styleRes}>
                    :
                  </Box>
                  <Box
                    component="td"
                    sx={{ pl: "15px", fontSize: { xs: "13px", sm: "16px" } }}>
                    Closed
                  </Box>
                </tr>
              </table>
            </Box>
          </Col>
          <Col sx={{ mt: { xs: 3, sm: 0 } }} lg={4}>
            <Box
              sx={{
                display: "flex",
                gap: "15px",
              }}>
              <img height={"197px"} src={phone} alt="Image phone" />
              <Box className="map-site">
                <Box className="map-title">
                  <Typography
                    variant="h5"
                    fontWeight="bold"
                    sx={{ marginBottom: 0 }}>
                    TBT GROUPS
                  </Typography>
                  <Typography
                    variant="body1"
                    fontSize="12px"
                    sx={{ marginBottom: "5px" }}>
                    MOBILE APP NOW AVAILABLE
                  </Typography>
                </Box>
                <Box className="map-qr">
                  <img src={mapQr} alt="" />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "163px",
                    height: "30px",
                    position: "relative",
                    bottom: 35,
                  }}>
                  <Box
                    sx={{ width: "70px" }}
                    onClick={() =>
                      window.open(
                        "https://apps.apple.com/us/app/tbt-groups/id6459303666",
                        "_blank",
                        "rel=noopener noreferrer"
                      )
                    }></Box>
                  <Box
                    sx={{ width: "70px" }}
                    onClick={() =>
                      window.open(
                        "https://play.google.com/store/apps/details?id=com.tbtgroups.susoft.vn",
                        "_blank",
                        "rel=noopener noreferrer"
                      )
                    }></Box>
                </Box>
              </Box>
            </Box>
          </Col>
        </Row>
      </Container>

      <Box
        sx={{
          bgcolor: "background.footerBar",
          py: "7px",
          textAlign: "center",
          marginBottom: "-5px",
        }}>
        <Typography component="h5" color="white" fontWeight="400" mb={0}>
          @2023 - TBT GROUPS
        </Typography>
      </Box>

      <ScrollToArrow />
    </Box>
  );
};

export default Footer;
