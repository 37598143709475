import { Store, createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { routerMiddleware } from "connected-react-router";
import { composeWithDevTools } from "redux-devtools-extension";
import { History, createBrowserHistory } from "history";
import _ from "lodash";
import { loadState, saveState } from "./utils/storage";
import rootReducer, { RootState } from "./redux";

export default function configureStore(): Store<RootState, any> {
  const history = createBrowserHistory();
  const composeEnhancers = composeWithDevTools({});
  const persistedState = loadState();
  const store = createStore(
    rootReducer(history),
    persistedState,
    composeEnhancers(applyMiddleware(routerMiddleware(history), thunk))
  );

  store.subscribe(
    _.throttle(() => {
      saveState(store.getState());
    }, 1000)
  );

  return store;
}
