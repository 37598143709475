import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import ClearIcon from "@mui/icons-material/Clear";
import SaveIcon from "@mui/icons-material/Save";

import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  Modal,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { theme } from "../../../../utils/theme";
import LoadingButton from "@mui/lab/LoadingButton";
import { callHttpClientMethod } from "../../../../services/callHttpClient";
import { BASE_URL, HttpMethod } from "../../../../services/api.constant";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../../../redux";
import { changePass } from "../../../../services/apiList";
import { useNavigate } from "react-router";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { TypesAction } from "../../../../redux/typesAction";

function ChangePassword() {
  const { auth } = useSelector((state: RootState) => state.auth);
  const navigate = useNavigate();
  const [newPassword, setNewPassword] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [openChange, setOpenChange] = useState(false);
  const handleOpenChangePass = () => setOpenChange(true);
  const handleCloseChangePass = () => setOpenChange(false);

  const [showNew, setShowNew] = useState(true);
  const [showConfirm, setShowConfirm] = useState(true);

  const [loading, setLoading] = useState(false);
  const handleClickShowPassword = () => setShowNew((show) => !show);
  const handleClickConfirmPassword = () => setShowConfirm((show) => !show);
  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  const dispatch = useDispatch();
  const style = {
    width: { xs: "75%", sm: "50%", md: "35%" },
    position: "absolute" as "absolute",
    top: { xs: "40%", sm: "50%" },
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    padding: 20,
    boxShadow: 24,
    borderRadius: 5,
    outLine: "none",
    p: 4,
    display: "flex",
    flexDirection: "column",
  };

  const handleUpdatePassWord = async () => {
    setLoading(true);
    const data = {
      current_password: currentPassword,
      new_password: confirmPassword,
    };
    const responsive = await callHttpClientMethod(
      BASE_URL + changePass(),
      HttpMethod.Post,
      data,
      {
        headers: {
          Authorization: `Bearer ${auth?.access_token}`,
        },
      }
    );
    if (responsive?.errors) {
      toast.error("Error change password!");

      setLoading(false);
    } else if (responsive?.status_code === 500) {
      toast.error("Error change password!");

      setLoading(false);
    } else if (responsive?.status_code === 200) {
      toast.success("Password changed successfully!");
      setLoading(false);

      dispatch({
        type: TypesAction.AUTH,
        payload: {},
      });
      dispatch({
        type: TypesAction.USERINFO,
        payload: {},
      });

      navigate("/login");
    }
  };
  const responChangePass = { width: { xs: "100%", sm: "70%" } };
  return (
    <Box>
      <Typography
        onClick={handleOpenChangePass}
        sx={{
          color: `${theme.palette.common.green}`,
          cursor: "pointer",
          textAlign: "right",
        }}>
        Change
      </Typography>
      <Modal
        open={openChange}
        onClose={handleCloseChangePass}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disablePortal>
        <Box sx={style}>
          <Typography sx={{ textAlign: "center", fontWeight: 600 }}>
            CHANGE PASSWORD
          </Typography>
          <Box
            onClick={handleCloseChangePass}
            sx={{ position: "absolute", right: 4, top: 4 }}>
            <ClearIcon />
          </Box>

          <FormControl>
            <TextField
              sx={{ responChangePass }}
              required
              type="password"
              color={"success"}
              onChange={(e) => setCurrentPassword(e.target.value)}
              placeholder="Current password"></TextField>
          </FormControl>

          <FormControl>
            <OutlinedInput
              sx={{ responChangePass }}
              required
              type={showNew ? "password" : "text"}
              error={newPassword.length < 8 && newPassword.length > 0}
              onChange={(e) => setNewPassword(e.target.value)}
              color={
                newPassword.length < 8 && newPassword.length > 0
                  ? "error"
                  : "success"
              }
              placeholder="New password"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    sx={{ fontSize: 5 }}
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end">
                    {showNew ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }></OutlinedInput>
            {newPassword.length < 8 && newPassword.length > 0 && (
              <Typography
                sx={{
                  width: "100%",
                  color: "#f02849",
                  marginTop: -2,
                }}>
                The password must be at least 8 characters
              </Typography>
            )}
          </FormControl>
          <FormControl>
            <OutlinedInput
              id="enter_change_pass"
              sx={{ responChangePass }}
              required
              type={showConfirm ? "password" : "text"}
              error={
                newPassword !== confirmPassword && confirmPassword.length > 0
              }
              onChange={(e) => setConfirmPassword(e.target.value)}
              color={
                newPassword !== confirmPassword && confirmPassword.length > 0
                  ? "error"
                  : "success"
              }
              placeholder="Confirm new password"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    sx={{ fontSize: 5 }}
                    aria-label="toggle password visibility"
                    onClick={handleClickConfirmPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end">
                    {showConfirm ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }></OutlinedInput>
            {newPassword !== confirmPassword && confirmPassword.length > 0 && (
              <Typography
                sx={{
                  width: "100%",
                  color: "#f02849",
                  marginTop: -2,
                }}>
                Password does not match.
              </Typography>
            )}
          </FormControl>
          <FormControl>
            {!loading ? (
              <Button
                sx={{
                  paddingLeft: 4,
                  paddingRight: 4,
                  borderRadius: 10,
                  width: 200,
                  margin: "auto",
                  backgroundColor: "#e4e5e9",
                  color: `${theme.palette.text.body}`,
                  ":hover": { backgroundColor: "#e4e5e9" },
                }}
                disabled={
                  currentPassword === "" ||
                  newPassword.length < 8 ||
                  newPassword !== confirmPassword
                }
                variant="contained"
                color="success"
                onClick={handleUpdatePassWord}>
                Save
              </Button>
            ) : (
              <LoadingButton
                sx={{
                  paddingLeft: 4,
                  paddingRight: 4,
                  width: 200,
                  borderRadius: 10,
                  margin: "auto",
                }}
                loading
                loadingPosition="start"
                startIcon={<SaveIcon />}
                variant="outlined">
                <span>Save</span>
              </LoadingButton>
            )}
          </FormControl>
        </Box>
      </Modal>
    </Box>
  );
}

export default ChangePassword;
