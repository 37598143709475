import { Box, Input, MenuItem, Typography } from "@mui/material";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { callHttpClientMethod } from "../../services/callHttpClient";
import { BASE_URL, HttpMethod } from "../../services/api.constant";
import { historyOrders, keyWord, productLists } from "../../services/apiList";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { RootState } from "../../redux";
import { TypesAction } from "../../redux/typesAction";
import Tippy from "@tippyjs/react/headless";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import SearchIcon from "@mui/icons-material/Search";

import { HistorySearch } from "../../gobalType";

function Search() {
  const { auth } = useSelector((state: RootState) => state.auth);

  const { tokenPush } = useSelector(
    (state: RootState) => state.listNotification
  );
  const [historySearch, setHistorySearch] = useState<HistorySearch[]>([]);

  const [showMenu, setShowMenu] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const [search, setSearch] = useState<string>("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = window.location.pathname;

  const loadData = async (keyword: string) => {
    dispatch({
      type: TypesAction.GET_LIST_PRODUCTS,
      payload: { loadingPf: true },
    });
    const data = {
      category_id: currentPath === "/products-plants" ? 1 : 2,
      search: keyword,
      order_by: "desc",
      device_token: tokenPush,
    };

    const response = await callHttpClientMethod(
      BASE_URL + productLists(),
      HttpMethod.Get,
      data,
      {
        headers: {
          Authorization: `Bearer ${auth?.access_token}`,
        },
      }
    );

    dispatch({
      type: TypesAction.GET_LIST_PRODUCTS,
      payload: { data: response, loadingPf: false },
    });
  };

  const loadOrderHis = async (keyword: string) => {
    const data = {
      search: search,
      order_by: "desc",
      device_token: tokenPush,
    };
    const response = await callHttpClientMethod(
      BASE_URL + historyOrders(),
      HttpMethod.Get,
      data,
      {
        headers: {
          Authorization: `Bearer ${auth?.access_token}`,
        },
      }
    );
    if (response.status_code === 200) {
      dispatch({ type: TypesAction.GET_ORDERS, payload: response.data });
    }
  };
  const handleGoToSearch = (event: any, keyword: string) => {
    event.preventDefault();
    setShowMenu(false);

    if (currentPath === "/order-history") {
      loadOrderHis(keyword);
    } else {
      loadData(keyword);
    }
  };

  const getHistorySearchProduct = async () => {
    let data;
    if (
      currentPath === "/products-plants" ||
      currentPath === "/products-pots"
    ) {
      data = {
        type: 1,
        device_token: tokenPush,
      };
    } else if (currentPath === "/order-history") {
      data = {
        type: 2,
        device_token: tokenPush,
      };
    }
    const response = await callHttpClientMethod(
      BASE_URL + keyWord(),
      HttpMethod.Get,
      data,
      {
        headers: {
          Authorization: `Bearer ${auth?.access_token}`,
        },
      }
    );
    setHistorySearch(response);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setSearch("");
    setShowMenu(false);
  }, [currentPath]);

  return (
    <Box>
      {(currentPath === "/products-plants" ||
        currentPath === "/products-pots" ||
        currentPath === "/order-history") && (
        <Tippy
          interactive
          placement="bottom-start"
          visible={showMenu && historySearch.length > 0}
          onClickOutside={() => setShowMenu(false)}
          render={(attrs) => (
            <Box
              tabIndex={-1}
              {...attrs}
              className={"wrapper"}
              sx={{
                width: "100%",
                minWidth: { xs: "100px", sm: "180px" },
                inset: "none",
                margin: "none",
                transform: "none",
              }}>
              {historySearch?.length > 0 ? (
                <Box>
                  {historySearch?.map((item, index) => {
                    return (
                      <MenuItem
                        onClick={(e) => {
                          setSearch(item?.keyword);
                          handleGoToSearch(e, item?.keyword);
                        }}
                        sx={{
                          marginBottom: 0,
                          padding: {
                            xs: "0 10px",
                            sm: "10px 20px 10px 10px",
                          },
                        }}
                        key={index}>
                        <AccessTimeOutlinedIcon
                          sx={{ fontSize: { xs: 12, sm: 16 }, marginRight: 1 }}
                        />
                        <Typography
                          sx={{
                            marginBottom: 0,
                            fontSize: { xs: 12, sm: 16 },
                          }}>
                          {item?.keyword}
                        </Typography>
                      </MenuItem>
                    );
                  })}
                </Box>
              ) : (
                <Box sx={{ fontSize: { xs: 12, sm: 16 }, textAlign: "center" }}>
                  No recent searches.
                </Box>
              )}
            </Box>
          )}>
          <form onSubmit={(e) => handleGoToSearch(e, search)}>
            <Box
              className="input-container"
              sx={{
                minWidth: "180px",
              }}>
              <Box sx={{ flex: 1, width: "100%" }}>
                <input
                  ref={inputRef}
                  onClick={() => {
                    setShowMenu(true);

                    getHistorySearchProduct();
                  }}
                  className="input-search"
                  placeholder="Search name"
                  value={search}
                  onChange={(e) => {
                    setSearch(e.target.value);
                    if (
                      currentPath === "/products-plants" ||
                      (currentPath === "/products-pots" &&
                        e.target.value === "")
                    ) {
                      loadData("");
                    } else if (
                      currentPath === "/order-history" &&
                      e.target.value === ""
                    )
                      loadOrderHis("");
                  }}
                />
              </Box>
              <Box sx={{ with: "10%" }}>
                <SearchIcon
                  className={"icon-search"}
                  onClick={(e) => {
                    search !== "" && handleGoToSearch(e, search);
                  }}
                  sx={{
                    borderLeft: "1px solid #c4c4c4",
                    color: " #7C7A7A",
                    height: { xs: 20, sm: 25 },
                    width: { xs: 20, sm: 25 },
                    paddingLeft: { xs: "3px", sm: 1 },
                    paddingRight: { xs: "8px", sm: "13px" },
                  }}
                />
              </Box>
            </Box>
          </form>
        </Tippy>
      )}
    </Box>
  );
}

export default Search;
