import { Box, Button, Modal } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { theme } from "../../../../utils/theme";
interface ModalProps {
  isOpen: boolean;
  urlAvatar: string;
  handleClose?: () => void;
  handleUpload?: () => void;
}
function ModalPreviewAvatar(props: ModalProps) {
  const style = {
    position: "absolute" as "absolute",
    top: "55%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { xs: "75%", sm: "50%", md: "35%" },
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: 2,
    textAlign: "center",
  };
  const { isOpen, handleClose, urlAvatar, handleUpload } = props;
  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Box sx={style}>
        <Box
          onClick={handleClose}
          sx={{ position: "absolute", right: 4, top: 4 }}>
          <ClearIcon />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}>
          <Box
            mb={2}
            component={"img"}
            src={urlAvatar}
            sx={{
              alignItems: "center",
              width: "100%",
              height: { md: "500px" },
              objectFit: "fill",
            }}
          />
          <Button
            sx={{
              paddingLeft: 4,
              paddingRight: 4,
              borderRadius: 10,
              width: 200,
              margin: "auto",
              backgroundColor: "#e4e5e9",
              color: `${theme.palette.text.body}`,
              ":hover": { backgroundColor: "#e4e5e9" },
            }}
            onClick={handleUpload}>
            Upload
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}

export default ModalPreviewAvatar;
