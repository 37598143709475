import * as React from "react";
import Box from "@mui/material/Box";
import { Link, useNavigate } from "react-router-dom";

import { useSelector } from "react-redux";
import { RootState } from "../../../redux";
import { Product, ProductSizes } from "../../../gobalType";
import { PATH_IMAGE_URL } from "../../../services/api.constant";
import { CircularProgress, Grid, Paper, Typography } from "@mui/material";
import { useDispatch } from "react-redux";

import "./style.scss";
import { TypesAction } from "../../../redux/typesAction";
import Pages from "../..";
import Search from "../../../components/Header/Seach";

const PotsPage = () => {
  const { product_lists, loadingPf } = useSelector(
    (state: RootState) => state.products
  );
  const { auth } = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handlePotDetails = (product: Product) => {
    navigate(`/product-details/${product.id}`, {
      state: {
        product_id: product?.product_sizes?.[0]?.product_id,
        size_id: product?.product_sizes?.[0]?.id,
      },
    });
  };
  if (loadingPf) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          margin: 32,
        }}>
        <CircularProgress color="success" />
      </Box>
    );
  }
  return (
    <Pages>
      <Box
        sx={{
          marginTop: { xs: 8, sm: 10 },
          backgroundColor: "transparent",
          paddingBottom: 5,
          width: { xs: "360px", sm: "650px", md: "800px", lg: "981px" },
        }}>
        {product_lists?.length > 0 ? (
          <Grid className="container" spacing={5} container>
            {product_lists?.map((product: Product, index: number) => (
              <Grid
                className="grid-item"
                item
                key={index}
                xs={6}
                sm={4}
                md={3}
                lg={3}>
                <Box
                  className="item-pots"
                  sx={{ width: { sm: "190px", md: "180px", lg: "215px" } }}
                  onClick={() => handlePotDetails(product)}>
                  <Box
                    className="image-pots"
                    sx={{
                      borderBottomRightRadius: "10px",
                      borderBottomLeftRadius: "10px",
                      width: {
                        xs: "140px",
                        sm: "170px",
                        md: "180px",
                        lg: "215px",
                      },
                      height: {
                        xs: "140px",
                        sm: "170px",
                        md: "170px",
                        lg: "220px",
                      },
                      paddingTop: { sm: 1, md: 0 },
                    }}
                    component="img"
                    src={`${
                      PATH_IMAGE_URL + product?.thumbnail?.[0]?.image_path
                    }`}
                    alt="image"
                  />

                  <div className="info-name-pots">{product.name}</div>
                </Box>
              </Grid>
            ))}
          </Grid>
        ) : (
          // </Container>
          <Box
            sx={{
              fontSize: { xs: 16, sm: 20 },
              marginBottom: "-20px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}>
            No data!
          </Box>
        )}
      </Box>
    </Pages>
  );
};

export default React.memo(PotsPage);
