import React, { memo } from "react";
import {
  ContainerProps,
  Container as UiControler,
  useTheme,
} from "@mui/material";

const Container: React.FC<ContainerProps> = ({
  children,
  sx,
  component = "div",
  maxWidth = "lg",
  disableGutters = true,
  ...props
}) => {
  const theme = useTheme();

  return (
    <UiControler
      disableGutters
      sx={{
        px: theme.breakpoints.spacing,
        ...sx,
      }}
      {...props}>
      {children}
    </UiControler>
  );
};

export default memo(Container);
