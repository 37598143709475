import { Box, Button, FormControl, TextField, Typography } from "@mui/material";
import Container from "../../../../components/Header/common/Container";
import { useEffect, useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import { theme } from "../../../../utils/theme";
import SaveIcon from "@mui/icons-material/Save";
import { callHttpClientMethod } from "../../../../services/callHttpClient";
import { BASE_URL, HttpMethod } from "../../../../services/api.constant";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router";
import { forgotPass } from "../../../../services/apiList";
import ValidateOtp from "../../../Register/components/ValidateOtp";
import { useDispatch } from "react-redux";
import { TypesAction } from "../../../../redux/typesAction";
import "../../style.scss";

function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const responsForgot = { width: { xs: "100%", sm: "70%" } };
  let regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  const handleForgotPassword = async () => {
    setLoading(true);
    const data = {
      email: email,
    };
    const responsive = await callHttpClientMethod(
      BASE_URL + forgotPass(),
      HttpMethod.Post,
      data,
      {}
    );

    if (responsive.message) {
      setLoading(false);
      dispatch({ type: TypesAction.VERIFYOTP, payload: email });
      navigate("/validateOtp", { state: 2 });
    } else {
      setLoading(false);
      toast.warning(
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}>
          <Typography sx={{ marginTop: 2, marginBottom: 0.5 }}>
            Notification
          </Typography>
          <Typography sx={{ marginTop: -1 }}>
            Invalid OTP. Please try again!
          </Typography>
        </div>
      );
    }
  };

  return (
    <Container maxWidth="xs" component="main">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
        className="bg_box_login">
        <Box sx={responsForgot}>
          <Typography
            sx={{
              fontSize: 25,
              textAlign: "center",
              color: `${theme.palette.text.body}`,
              fontWeight: 600,
            }}>
            RESET PASSWORD
          </Typography>
          <Typography>
            Enter your registered email below to recieve password reset
            intructions.
          </Typography>
          <FormControl style={{ width: "100%" }}>
            <label>Email address</label>
            <TextField
              style={{ width: "100%", border: "none" }}
              onChange={(e) => setEmail(e.target.value)}
              required
              error={!regex.test(email) && email.length > 0}
              disabled={loading}
              type="email"
              value={email}
              color={
                !regex.test(email) && email.length > 0 ? "error" : "success"
              }
              placeholder={"Enter email"}></TextField>
            {!regex.test(email) && email.length > 0 && (
              <Typography
                sx={{
                  width: "100%",
                  color: "#f02849",
                  marginTop: -2,
                  textAlign: "left",
                }}>
                Email invalidate.
              </Typography>
            )}
            {!loading ? (
              <Button
                sx={{
                  width: 200,
                  paddingTop: 1,
                  paddingBottom: 1,
                  borderRadius: 5,
                  marginBottom: 3,
                  margin: "auto",
                  backgroundColor: "#e4e5e9",
                  color: `${theme.palette.text.body}`,
                  ":hover": { backgroundColor: "#e4e5e9" },
                  fontWeight: 700,
                }}
                disabled={email === "" || !regex.test(email)}
                type="submit"
                onClick={handleForgotPassword}
                variant="contained">
                Send
              </Button>
            ) : (
              <LoadingButton
                sx={{
                  width: 200,
                  paddingTop: 1,
                  paddingBottom: 1,
                  borderRadius: 5,
                  margin: "auto",
                  marginBottom: 3,
                  display: "flex",
                  justifyContent: "center",
                }}
                loading
                loadingPosition="start"
                startIcon={<SaveIcon />}
                variant="outlined">
                Send
              </LoadingButton>
            )}
          </FormControl>
        </Box>
      </Box>
    </Container>
  );
}
export default ForgotPassword;
