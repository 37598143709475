import Box from "@mui/material/Box";
import { FC } from "react";

type IProps = {
  children?: React.ReactNode;
  style?: any;
};

const LargeText: FC<IProps> = ({ children, style }) => {
  return (
    <Box
      sx={[
        {
          fontSize: {
            xs: "15px",
            sm: "25px",
            md: "40px",
          },
        },
        style,
      ]}>
      {children}
    </Box>
  );
};

export default LargeText;
