import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import {
  Button,
  ButtonBase,
  FormControl,
  IconButton,
  InputAdornment,
  Modal,
  OutlinedInput,
  Typography,
} from "@mui/material";
import "./style.scss";
import { useState } from "react";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import { useDispatch, useSelector } from "react-redux";
import { BASE_URL } from "../../services/api.constant";
import { RootState } from "../../redux";
import { register } from "../../services/apiList";
import { useNavigate } from "react-router";
import { TypesAction } from "../../redux/typesAction";
import { theme } from "../../utils/theme";
import axios from "axios";
import { toast } from "react-toastify";

import CloudUploadIcon from "@mui/icons-material/CloudUpload";
export default function Register() {
  const [loading, setLoading] = useState(false);

  const [email, setEmail] = useState("");
  const [fullname, setFullname] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [address, setAddress] = useState("");
  const [newPass, setNewPass] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const responsRegis = { width: { xs: "100%", sm: "70%" } };
  const [showNew, setShowNew] = useState(true);
  const [showConfirm, setShowConfirm] = useState(true);
  const [imgCetificate, setImageCetificate] = useState("");

  const [file, setFile] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleClickShowPassword = () => setShowNew((show) => !show);
  const handleClickConfirmPassword = () => setShowConfirm((show) => !show);
  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  let regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

  const handleRegister = async () => {
    setLoading(true);
    const formData = new FormData();
    formData.append("certificate", imgCetificate);
    formData.append("email", email);
    formData.append("name", fullname);
    formData.append("full_name", fullname);

    formData.append("address", address);
    formData.append("phone_number", phoneNumber);
    formData.append("password", newPass);
    await axios({
      method: "post",
      url: `${BASE_URL + register()}`,
      data: formData,
    })
      .then(function (response) {
        if (response.status === 200) {
          dispatch({
            type: TypesAction.VERIFYOTP,
            payload: response.data.data.email,
          });
          navigate("/validateOtp", { state: 1 });
        }
        setLoading(false);
      })
      .catch(function (error) {
        setLoading(false);
        toast.error("The email has already been taken.");
      });
  };
  const handlePreviewCetificate = (e: any) => {
    setImageCetificate(e.target.files[0]);
    const fileURL = URL.createObjectURL(e.target.files[0]);
    fileURL && setFile(fileURL);
  };
  return (
    <Container
      component="main"
      maxWidth="xs"
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        marginBottom: 7,
        padding: 0,
      }}>
      <Box className="bg_box_register">
        <Typography
          sx={{
            fontSize: 25,
            textAlign: "center",
            color: `${theme.palette.text.body}`,
            fontWeight: 600,
          }}>
          REGISTER
        </Typography>
        <Box>
          <Typography
            sx={{
              fontSize: { xs: 12, sm: 16 },
              color: "red",
              margin: { sm: "10px 90px" },
            }}>
            For purchasing our products with tax-free, please kindly upload your
            Resale Certificate here.
          </Typography>
          <input
            accept="image/*"
            name="cetificate"
            style={{ display: "none" }}
            type="file"
            id="upload-cetificate"
            onChange={(e) => {
              handlePreviewCetificate(e);
            }}
          />

          <Button
            sx={{
              display: "flex",
              margin: "auto",
              textTransform: "capitalize",
              borderRadius: 10,
              width: { xs: 220, sm: 250 },

              backgroundColor: "#e4e5e9",
              color: `${theme.palette.text.body}`,
              ":hover": { backgroundColor: "#e4e5e9" },
              boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
            }}>
            <label htmlFor="upload-cetificate" style={{ cursor: "pointer" }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  margin: "auto",
                }}>
                <CloudUploadIcon
                  sx={{ color: `${theme.palette.common.green}`, mr: "5px" }}
                />
                <Typography
                  sx={{ marginBottom: 0, fontSize: { xs: 14, sm: 16 } }}>
                  Upload Resale Certificate
                </Typography>
              </Box>
            </label>
          </Button>
          <Box sx={{ margin: "25px 0" }}>
            {imgCetificate && (
              <Box
                component={"img"}
                src={file}
                sx={{
                  marginBottom: "25px",
                  display: "flex",
                  margin: "auto",
                  alignItems: "center",
                  width: "100%",
                  maxHeight: "400px",
                  objectFit: "fill",
                  borderRadius: "10px",
                }}
              />
            )}
          </Box>
        </Box>
        <FormControl sx={responsRegis}>
          <label>
            Email <span className="star-requied">*</span>
          </label>
          <TextField
            className="input-register"
            sx={{ width: "100%" }}
            disabled={loading}
            required
            placeholder="Enter email address"
            onChange={(e) => setEmail(e.target.value)}
            error={!regex.test(email) && email.length > 0}
            type="email"
            value={email}
            color={
              !regex.test(email) && email.length > 0 ? "error" : "success"
            }></TextField>
          {!regex.test(email) && email.length > 0 && (
            <Typography
              sx={{
                color: "#f02849",
                marginTop: -2,
              }}>
              Email invalidate.
            </Typography>
          )}
          <label>
            Fullname<span className="star-requied">*</span>
          </label>
          <TextField
            disabled={loading}
            className="input-register"
            sx={{ width: "100%" }}
            onChange={(e) => setFullname(e.target.value)}
            required
            color={"success"}
            placeholder="Enter fullname"></TextField>
          <label>
            Address<span className="star-requied">*</span>
          </label>
          <TextField
            className="input-register"
            sx={{ width: "100%" }}
            required
            color={"success"}
            disabled={loading}
            onChange={(e) => setAddress(e.target.value)}
            placeholder="Enter address"></TextField>
          <label>
            Phone Number<span className="star-requied">*</span>
          </label>
          <TextField
            disabled={loading}
            className="input-register"
            sx={{ width: "100%" }}
            required
            color={"success"}
            inputMode="numeric"
            onChange={(e) => setPhoneNumber(e.target.value)}
            placeholder="Enter phone number"></TextField>
        </FormControl>
        <FormControl sx={responsRegis}>
          <label>
            New password<span className="star-requied">*</span>
          </label>
          <OutlinedInput
            sx={{ width: "100%" }}
            required
            disabled={loading}
            type={showNew ? "password" : "text"}
            error={newPass.length < 8 && newPass.length > 0}
            onChange={(e) => setNewPass(e.target.value)}
            color={
              newPass.length < 8 && newPass.length > 0 ? "error" : "success"
            }
            placeholder="New password"
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  sx={{ fontSize: 5 }}
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end">
                  {showNew ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }></OutlinedInput>
          {newPass.length < 8 && newPass.length > 0 && (
            <Typography
              sx={{
                color: "#f02849",
                marginTop: -2,
              }}>
              The password must be at least 8 characters
            </Typography>
          )}
        </FormControl>
        <FormControl sx={responsRegis}>
          <label>
            Confirm new password<span className="star-requied">*</span>
          </label>
          <OutlinedInput
            sx={{ width: "100%" }}
            required
            disabled={loading}
            type={showConfirm ? "password" : "text"}
            error={newPass !== confirmPass && confirmPass.length > 0}
            onChange={(e) => setConfirmPass(e.target.value)}
            color={
              newPass !== confirmPass && confirmPass.length > 0
                ? "error"
                : "success"
            }
            placeholder="Confirm new password"
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  sx={{ fontSize: 5 }}
                  aria-label="toggle password visibility"
                  onClick={handleClickConfirmPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end">
                  {showConfirm ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }></OutlinedInput>
          {newPass !== confirmPass && confirmPass.length > 0 && (
            <Typography
              sx={{
                color: "#f02849",
                marginTop: -2,
              }}>
              Password does not match.
            </Typography>
          )}
        </FormControl>
        {/* <FormControl> */}
        {!loading ? (
          <Button
            sx={{
              paddingLeft: 4,
              paddingRight: 4,
              borderRadius: 10,
              width: 200,
              display: "flex",
              margin: "auto",
              backgroundColor: "#e4e5e9",
              color: `${theme.palette.text.body}`,
              ":hover": { backgroundColor: "#e4e5e9" },
            }}
            disabled={
              email === "" ||
              fullname === "" ||
              phoneNumber === "" ||
              address === "" ||
              confirmPass === "" ||
              newPass.length < 8 ||
              !regex.test(email) ||
              newPass !== confirmPass
            }
            type="submit"
            variant="contained"
            onClick={handleRegister}>
            Register
          </Button>
        ) : (
          <LoadingButton
            sx={{
              display: "flex",
              margin: "auto",
              paddingLeft: 4,
              paddingRight: 4,
              width: 200,
              borderRadius: 10,
            }}
            loading
            loadingPosition="start"
            startIcon={<SaveIcon />}
            variant="outlined">
            <span>Save</span>
          </LoadingButton>
        )}
        {/* </FormControl> */}
      </Box>
    </Container>
  );
}
