import { isNumber } from "lodash";
import { HttpMethod } from "./api.constant";
import { httpClient } from "./HttpClient";
import Swal from 'sweetalert2'

const esc = function (param) {
  return encodeURIComponent(param)
    .replace(/[!'()*]/g, escape)
    .replace(/%20/g, "+");
};

const cleanArray = function (actual) {
  const newArray = [];
  for (let i = 0; i < actual.length; i++) {
    if (actual[i]) {
      newArray.push(actual[i]);
    }
  }
  return newArray;
};

const httpBuildQuery = function (
  queryData,
  numericPrefix = null,
  argSeparator = "&",
  tempKey = null,
) {
  if (!queryData) {
    return "";
  }

  const query = Object.keys(queryData).map(function (k) {
    let res;
    let key = k;

    if (tempKey) {
      key = tempKey + "[" + key + "]";
    }

    if (typeof queryData[k] === "object" && queryData[k] !== null) {
      res = httpBuildQuery(queryData[k], null, argSeparator, key);
    } else {
      if (numericPrefix) {
        key = isNumber(key) ? numericPrefix + Number(key) : key;
      }

      let val = queryData[k];

      val = val === true ? "1" : val;
      val = val === false ? "0" : val;
      val = val === 0 ? "0" : val;
      val = val || "";

      res = esc(key) + "=" + esc(val);
    }

    return res;
  });

  return cleanArray(query)
    .join(argSeparator)
    .replace(/[!'()*]/g, "");
};

/**
 * fun get query
 * @param queryData
 * @param numericPrefix
 * @param argSeparator
 * @param tempKey
 * @returns {string|string}
 */
export const httpBuildQuerys = (
  queryData,
  numericPrefix = null,
  argSeparator = "&",
  tempKey = null,
) => {
  return httpBuildQuery(queryData, numericPrefix, argSeparator, tempKey);
};

/**
 * func get API url
 * @param path
 * @param query
 * @param appendQuery
 * @returns {string}
 */
export const getApiUrl = (path, query = null, appendQuery = false) => {
  if (!path) {
    let error = new Error();
    error.code = "E_API_UNKNOWN";

    throw error;
  }

  path = path.replace(/^api\//, "/");
  if (path.indexOf(":") > -1 && query) {
    Object.keys(query).forEach((key) => {
      if (path.indexOf(":" + key) > -1) {
        path = path.replace(":" + key, query[key]);
      }
    });
  }

  if (appendQuery && query) {
    path = path + (path.indexOf("?") > -1 ? "&" : "?") + httpBuildQuerys(query);
  }

  return path;
};

/**
 * func call to API by axios
 * @param url
 * @param method
 * @param data
 * @param setting
 * @returns {Promise<any | undefined>}
 */
export async function callHttpClientMethod(url, method, data, setting) {
  switch (method.toString().toLowerCase()) {
    case HttpMethod.Post:
      return httpClient
        .post(url, data, setting)
        .then((x) => (x ? x.data : undefined)).catch((err) => checkErr(err));
    case HttpMethod.Put:
      return httpClient.put(url, data).then((x) => (x ? x.data : undefined)).catch((err) => checkErr(err));
    case HttpMethod.Delete:
      return httpClient
        .delete(url, { data })
        .then((x) => (x ? x.data : undefined)).catch((err) => err);
    case HttpMethod.Patch:
      return httpClient.patch(url, data).then((x) => (x ? x.data : undefined)).catch((err) => checkErr(err));
    case HttpMethod.Get:
    default:
      return httpClient
        .get(getApiUrl(url, data, true), { ...setting, params: {} })
        .then((x) => (x ? x.data : undefined)).catch((err) => checkErr(err));
  }
}

function checkErr(err){
  if(err.message=="Unauthenticated."){
    window.location.replace('/login')
    return err
  }else{
    return err
  }
}
