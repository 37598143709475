import Box from "@mui/material/Box";
import { FC } from "react";

type IProps = {
  children?: React.ReactNode;
  style?: any;
};

const DefaultText: FC<IProps> = ({ children, style}) => {
  return (
    <Box
      sx={[
        {
          fontSize: {
            xs: "13px",
            sm: "14px",
            md: "16px",
          },
        },
        style,
      ]}
    >
      {children}
    </Box>
  );
};

export default DefaultText;
