import React, { memo } from "react";
import { Grid, GridProps, useTheme } from "@mui/material";

const Row: React.FC<GridProps> = ({
  children,
  container = true,
  sx,
  ...props
}) => {
  const theme = useTheme();

  return (
    <Grid
      container
      sx={{
        mx: `-${theme.breakpoints.spacing}`,
        width: "auto",
        ...sx,
      }}
      {...props}
    >
      {children}
    </Grid>
  );
};

export default memo(Row);
