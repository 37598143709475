import React, { memo } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Container from "../Header/common/Container";
import imageLogo from "../../assets/Images/logo.png";
import { Link } from "react-router-dom";
import { MenuType } from "./typing";
import MenuMain from "./MenuMain";
import MenuMobile from "./MenuMobile";
import Profile from "./Profile";
import { useDispatch } from "react-redux";
import { TypesAction } from "../../redux/typesAction";

const pages: MenuType[] = [
  {
    id: 1,
    title: "HOME",
    link: "/home",
  },
  {
    id: 2,
    link: "/about-us",
    title: "ABOUT US",
  },
  {
    id: 3,
    title: "PLANTS",
    link: "/products-plants",
  },
];

const Header: React.FC<{}> = () => {
  const dispatch = useDispatch();
  return (
    <AppBar
      position="sticky"
      id="back-to-top-anchor"
      sx={{
        bgcolor: "background.header",
        color: "text.body",
        zIndex: 99999,
        maxWidth: "xs",
      }}>
      <Container>
        <Toolbar disableGutters sx={{ justifyContent: "space-between" }}>
          <Link to="/home">
            <img src={imageLogo} style={{ width: 70, height: 70 }} />
          </Link>
          <Toolbar disableGutters sx={{ alignItems: "flex-start" }}>
            <MenuMain pages={pages} />
            <Profile />
            <MenuMobile pages={pages} />
          </Toolbar>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default memo(Header);
