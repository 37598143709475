import React from "react";
import ReactDOM from "react-dom/client";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import Footer from "./components/Footer";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./utils/theme";
import { Box } from "@mui/material";
import "./globalStyle.scss";
import Container from "./components/Header/common/Container";
import Header from "./components/Header";
import configureStore from "./configureStore";
import { ToastContainer } from "react-toastify";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

export const store = configureStore();

root.render(
  // <React.StrictMode>
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <BrowserRouter>
        <Header />
        <ToastContainer
          style={{
            top: 80,
            paddingTop: -2,
            paddingBottom: -2,
          }}
          position="top-right"
          autoClose={4000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />

        <Box
          component="main"
          sx={{
            bgcolor: {
              xs: "white",
              sm: "white",
              md: "background.body",
            },
            overflow: "hidden",
            paddingTop: "60px",
            minHeight: { xs: "140px", sm: "210px" },
          }}>
          <Container
            sx={{
              paddingLeft: { xs: 0, sm: "0", md: "15px" },
              paddingRight: { xs: 0, sm: "0", md: "15px" },
            }}>
            <App />
          </Container>
        </Box>
        <Footer />
      </BrowserRouter>
    </Provider>
  </ThemeProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
