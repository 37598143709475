import { Box } from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";

export const TimeNoti = ({ time }: { time: string }) => {
  const [timeAgo, setTimeAgo] = useState("");

  useEffect(() => {
    const calculateTimeAgo = () => {
      const now = moment();
      const then = moment(time);
      const diffInSeconds = now.diff(then, "seconds");

      if (diffInSeconds < 60) {
        setTimeAgo("Just now");
      } else if (diffInSeconds < 3600) {
        const diffInMinutes = Math.floor(diffInSeconds / 60);
        setTimeAgo(`${diffInMinutes} minutes ago`);
      } else if (diffInSeconds < 86400) {
        const diffInHours = Math.floor(diffInSeconds / 3600);
        setTimeAgo(`${diffInHours} hours ago`);
      } else {
        const diffInDays = Math.floor(diffInSeconds / 86400);
        setTimeAgo(`${diffInDays} days ago`);
      }
    };

    calculateTimeAgo();
  }, [time]);
  return <Box>{timeAgo}</Box>;
};
