import { Reducer } from "redux";
import States from "./state";
import { TypesAction } from "../typesAction";

const initialState: States = {
  product_lists: [],
  category_id: 0,
  product_detail: {},
  is_show_input: false,
  loadingPf: false,
};

const ProductsReducer: Reducer<States> = (state = initialState, action) => {
  switch (action.type) {
    case TypesAction.GET_LIST_PRODUCTS:
      const { data, loadingPf } = action.payload;

      return {
        ...state,
        product_lists: data,
        loadingPf: loadingPf,
      };

    case TypesAction.CATEGORY_ID:
      return {
        ...state,
        category_id: action.payload,
      };
    case TypesAction.PRODUCT_DETAIL:
      return {
        ...state,
        product_detail: action.payload,
      };
    case TypesAction.SHOW_INPUT_SEARCH:
      return {
        ...state,
        is_show_input: action.payload,
      };

    default:
      return state;
  }
};
export default ProductsReducer;
