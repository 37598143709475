export const BASE_URL = "https://tbtv2.susoft.vn/api";
export const PATH_IMAGE_URL = "https://tbtv2.susoft.vn/";

export const HttpMethod = {
  Post: "post",
  Get: "get",
  Delete: "del",
  Put: "put",
  Patch: "patch",
};
