import { Reducer } from "redux";
import States from "./state";
import { TypesAction } from "../typesAction";

const initialState: States = {
  auth: {
    access_token: "",
  },
  loading: false,
  user: {},
  verify: "",
  updateAvatar: "",
};

const AuthReducer: Reducer<States> = (state = initialState, action) => {
  switch (action.type) {
    case TypesAction.AUTH:
      const { data } = action.payload;
      return {
        ...state,
        auth: data,
        // loading: loading,
      };
    case TypesAction.USERINFO:
      const { dataUser, loadingUser } = action.payload;
      return {
        ...state,
        user: dataUser,
        loading: loadingUser,
      };
    case TypesAction.VERIFYOTP:
      return {
        ...state,
        verify: action.payload,
      };
    case TypesAction.UPDATE_AVATAR:
      return {
        ...state,
        updateAvatar: action.payload,
      };

    default:
      return state;
  }
};
export default AuthReducer;
