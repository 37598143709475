import {
  Breakpoint,
  createTheme,
  TypographyVariantsOptions,
} from "@mui/material/styles";

declare module "@mui/material/styles" {
  interface CommonColors {
    black: string;
    white: string;
    green: string;
  }

  interface TypeBackground {
    header: string;
    body: string;
    footerBar: string;
    footer: string;
  }

  interface TypeText {
    body: string;
    footer: string;
    highlight: string;
  }

  interface Palette {
    dark: Palette["primary"];
  }

  interface PaletteOptions {
    dark?: PaletteOptions["primary"];
  }

  interface BreakpointOverrides {
    xl: false;
  }

  interface Breakpoints {
    maxWidth: Breakpoint;
    spacing: string | number;
  }

  interface TypographyVariants {
    lineHeightHeading: React.CSSProperties["lineHeight"];
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    lineHeightHeading: React.CSSProperties["lineHeight"];
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    span: true;
    lineHeightHeading: true;
  }
}

// Update the Button's color options to include an ochre option
declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    dark: true;
  }
}

export let theme = createTheme({
  breakpoints: {
    maxWidth: "lg",
    spacing: "15px",
  },
  typography: {
    fontFamily: ['"Helvetica Neue"', "Roboto", "Arial", "sans-serif"].join(","),
    fontSize: 16,
    lineHeightHeading: 1.25,
  },
  palette: {
    dark: {
      main: "#E4E5E9",
      light: "#E4E5E9",
      dark: "#E4E5E9",
      contrastText: "#333",
    },
    common: {
      green: "#007F18",
    },
    background: {
      body: "#F4F4F4",
      footer: "#DADADA",
    },
    text: {
      footer: "#333",
      body: "#333",
      highlight: "#007F18",
    },
  },
});

theme = createTheme(theme, {
  breakpoints: {
    values: {
      lg: 1029,
    },
  },
  palette: {
    background: {
      footerBar: theme.palette.common.green,
      header: theme.palette.common.white,
    },
    common: {
      black: "#333",
    },
    text: {
      primary: "#333",
    },
  },
  typography: {
    body1: {
      fontSize: "16px",
      color: theme.palette.text.body,
      marginBottom: "1rem",
    },
    h1: {
      fontSize: "36px",
      color: theme.palette.text.body,
      fontWeight: theme.typography.fontWeightBold,
      lineHeight: theme.typography.lineHeightHeading,
      marginBottom: ".5rem",
    },
    h2: {
      fontSize: "32px",
      color: theme.palette.text.body,
      fontWeight: theme.typography.fontWeightBold,
      lineHeight: theme.typography.lineHeightHeading,
      marginBottom: ".5rem",
    },
    h3: {
      fontSize: "28px",
      color: theme.palette.text.body,
      fontWeight: theme.typography.fontWeightBold,
      lineHeight: theme.typography.lineHeightHeading,
      marginBottom: ".5rem",
    },
    h4: {
      fontSize: "24px",
      color: theme.palette.text.body,
      fontWeight: theme.typography.fontWeightMedium,
      lineHeight: theme.typography.lineHeightHeading,
      marginBottom: ".5rem",
    },
    h5: {
      fontSize: "20px",
      color: theme.palette.text.body,
      fontWeight: theme.typography.fontWeightMedium,
      lineHeight: theme.typography.lineHeightHeading,
      marginBottom: ".5rem",
    },
    h6: {
      fontSize: "16px",
      color: theme.palette.text.body,
      fontWeight: theme.typography.fontWeightRegular,
      lineHeight: theme.typography.lineHeightHeading,
      marginBottom: ".5rem",
    },
  },
});
