import styled from "@emotion/styled";
import { theme } from "../../../utils/theme";

export const ButtonPlant = styled.button`
  width: 97%;
  border-radius: 5px;
  border: none;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  cursor: pointer;

  font-weight: 500;
  padding: 7px 1px;
  color: ${theme.palette.text.body};
  &:hover {
    background-color: #9acc99 !important;
  }
`;
