import { useSelector } from "react-redux";
import { RootState } from "../../../../redux";
import { Box, Button, Checkbox, Typography } from "@mui/material";
import MarkChatReadOutlinedIcon from "@mui/icons-material/MarkChatReadOutlined";
import { BASE_URL, HttpMethod } from "../../../../services/api.constant";
import { callHttpClientMethod } from "../../../../services/callHttpClient";
import { detailNoti, readNoti } from "../../../../services/apiList";

import { useNavigate } from "react-router";
import { memo, useState } from "react";
import logo from "../../../../assets/Images/logo.png";

import { TimeNoti } from "../TimeNoti";
import { theme } from "../../../../utils/theme";
interface IProps {
  data: any;
  isChoice: boolean;
  getDetail?: any;
  onChoice: any;
}
function ItemNoti({ data, isChoice, onChoice }: IProps) {
  const { auth } = useSelector((state: RootState) => state.auth);
  const navigate = useNavigate();
  const [isRead, setIsRead] = useState(data?.status);

  const handleDetailNoti = async (id: number) => {
    const respon = await callHttpClientMethod(
      BASE_URL + detailNoti(id),
      HttpMethod.Get,
      {},
      {
        headers: {
          Authorization: `Bearer ${auth?.access_token}`,
        },
      }
    );
    console.log(respon, "respon");

    if (respon.status_code === 200) {
      navigate("/order-detail", { state: data?.order.order_code });
    } else {
      console.log("call api failed");
    }
  };

  const handleReadNoti = async (id: number) => {
    const respon = await callHttpClientMethod(
      BASE_URL + readNoti(),
      HttpMethod.Post,
      { id: id },
      {
        headers: {
          Authorization: `Bearer ${auth?.access_token}`,
        },
      }
    );
    if (respon?.status_code === 200) {
      setIsRead(1);
    } else {
      console.log("call readNoti failed");
    }
  };
  return (
    <Box
      sx={{
        backgroundColor: isChoice || isRead === 0 ? "#F2F6FC" : "#fff",
      }}
      className={"item-noti"}>
      <Box
        onClick={() => handleDetailNoti(data?.id)}
        sx={{ marginLeft: { xs: "-15px", sm: 0 }, display: "flex" }}>
        <Box
          component={"img"}
          src={logo}
          sx={{
            border: "1px solid #ccc",
            borderRadius: "100%",
            backgroundColor: "#fff",
            marginRight: 2,
            objectFit: "cover",
            width: "45px",
            height: "45px",
            padding: "5px",
          }}
        />
        <Box>
          <Typography id="fs-noti" style={{ marginBottom: 0, width: "92%" }}>
            {data?.msg}
          </Typography>
          <Typography id="fs-noti" sx={{ color: "#919191" }}>
            <TimeNoti time={data?.created_at} />
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}>
        <Box className={isRead === 0 ? "icon-mark-read" : "icon-mark-disable"}>
          <Box onClick={() => handleReadNoti(data?.id)}>
            <MarkChatReadOutlinedIcon
              sx={{
                fontSize: { xs: 18, sm: 23 },
                marginTop: "3px",
                color: isRead === 0 ? `${theme.palette.common.green}` : "#ccc",
              }}
            />
          </Box>

          <Box className="mark-read" sx={{ fontSize: { xs: 12, sm: 16 } }}>
            Mark as read
          </Box>
        </Box>
        <Box className="icon-select">
          <Checkbox
            checked={isChoice}
            onClick={onChoice}
            sx={{
              "& .MuiSvgIcon-root": {
                fontSize: { xs: 18, sm: 23 },
                borderColor: "#333",
              },
            }}
          />
          <Box className="select " sx={{ fontSize: { xs: 12, sm: 16 } }}>
            {isChoice ? "Unselect" : "Select"}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default memo(ItemNoti);
