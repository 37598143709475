import Modal from "@mui/material/Modal";
import { CircularProgress } from "@mui/material";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
};

interface LoadingProps {
  isOpen: boolean;
}

export default function LoadingCart(props: LoadingProps) {
  const { isOpen } = props;

  return (
    <Modal
      open={isOpen}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <CircularProgress color="success" sx={style} />
    </Modal>
  );
}
