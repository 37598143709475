import React, { useRef, useState } from "react";
import OtpInput from "react-otp-input";
import Container from "../../../components/Header/common/Container";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  Modal,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import { theme } from "../../../utils/theme";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { RootState } from "../../../redux";
import { callHttpClientMethod } from "../../../services/callHttpClient";
import { BASE_URL, HttpMethod } from "../../../services/api.constant";
import {
  resendOtpRegister,
  resetPass,
  validateOtp,
  validateOtpRegister,
} from "../../../services/apiList";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router";
import ClearIcon from "@mui/icons-material/Clear";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import "../style.scss";

const padTime = (time: any) => {
  return String(time).length === 1 ? `0${time}` : `${time}`;
};

const format = (time: any) => {
  // Convert seconds into minutes and take the whole part
  const minutes = Math.floor(time / 60);

  // Get the seconds left after converting minutes
  const seconds = time % 60;

  //Return combined values as string in format mm:ss
  return `0${minutes}:${padTime(seconds)}`;
};

function ValidateOtp() {
  const [otp, setOtp] = useState("");
  const [counter, setCounter] = React.useState(120);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [newPass, setNewPass] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const [showNew, setShowNew] = useState(true);
  const [showConfirm, setShowConfirm] = useState(true);
  const { verify } = useSelector((state: RootState) => state.auth);
  const navigate = useNavigate();
  const location = useLocation();

  const handleClickShowPassword = () => setShowNew((show) => !show);
  const handleClickConfirmPassword = () => setShowConfirm((show) => !show);
  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    width: { sm: "500px", xs: "80%" },
    boxShadow: 24,
    borderRadius: 4,
    outLine: "none",
    p: 4,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleVerifyEmail = async () => {
    setLoading(true);

    const dataRegister = {
      email: verify,
      otp: otp,
    };
    const dataForgot = {
      email: verify,
      otp: otp,
    };

    const responsive = await callHttpClientMethod(
      BASE_URL + (location.state === 2 ? validateOtp() : validateOtpRegister()),
      HttpMethod.Post,
      location.state === 2 ? dataForgot : dataRegister,
      {}
    );

    if (responsive.status_code === 200) {
      setLoading(false);

      location.state === 1 ? handleOtpRegister() : handleOpen();
    } else {
      toast.warning(
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}>
          <Typography sx={{ marginTop: 2, marginBottom: 0.5 }}>
            Notification
          </Typography>
          <Typography sx={{ marginTop: -1 }}>
            Invalid OTP. Please try again!
          </Typography>
        </div>
      );
      setLoading(false);
    }
  };
  const handleOtpRegister = () => {
    navigate("/login");
    toast.success("Successfully Verified! ");
  };

  const handleResetPassword = async () => {
    const data = {
      email: verify,
      otp: otp,
      password: newPass,
    };

    const res = await callHttpClientMethod(
      BASE_URL + resetPass(),
      HttpMethod.Post,
      data,
      {}
    );
    if (res.status_code === 200) {
      navigate("/login");
      toast.success("Account password changed successfully!");
    } else
      toast.warning(
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}>
          <Typography sx={{ marginTop: 2, marginBottom: 0.5 }}>
            Notification
          </Typography>
          <Typography sx={{ marginTop: -1 }}>{res.message}</Typography>
        </div>
      );
  };

  const handleResendOtp = async () => {
    const data = {
      email: verify,
    };
    await callHttpClientMethod(
      BASE_URL + resendOtpRegister(),
      HttpMethod.Post,
      data,
      {}
    );
    setCounter(120);
  };

  React.useEffect(() => {
    let timer: any;
    if (counter > 0) {
      timer = setTimeout(() => setCounter((c) => c - 1), 1000);
    }

    return () => {
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [counter]);
  return (
    <Container
      maxWidth="xs"
      component="main"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginBottom: 7,
        padding: 0,
      }}>
      <Box className="bg_box_register">
        <Typography
          sx={{
            fontSize: 25,
            textAlign: "center",
            color: `${theme.palette.text.body}`,
            fontWeight: 600,
          }}>
          OTP Verification
        </Typography>
        <Typography>
          Enter the 6 digit verification code recieved on your Email ID
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
            }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}>
              <Typography sx={{ marginRight: 1 }}>Verifycation code</Typography>
              <Typography
                sx={{
                  color: `${theme.palette.common.green}`,
                  fontWeight: 600,
                }}>
                {counter === 0 ? format(counter) : <div>{format(counter)}</div>}
              </Typography>
            </Box>
            <Button disabled={counter > 0} sx={{ marginBottom: 2 }}>
              <Typography
                sx={{
                  color:
                    counter === 0 ? `${theme.palette.common.green}` : "#ccc",
                  textTransform: "capitalize",
                  marginBottom: 0,
                }}
                onClick={handleResendOtp}>
                Resend OTP
              </Typography>
            </Button>
          </Box>
          <OtpInput
            value={otp}
            onChange={setOtp}
            numInputs={6}
            inputStyle={{
              padding: 10,
              margin: 7,
              fontSize: 16,
              marginBottom: 40,
              borderWidth: 1,
            }}
            renderSeparator={<span></span>}
            renderInput={(props) => <input {...props} />}
          />
          {!loading ? (
            <Button
              sx={{
                width: 200,
                paddingTop: 1,
                paddingBottom: 1,
                borderRadius: 5,
                marginBottom: 3,
                backgroundColor: "#e4e5e9",
                color: `${theme.palette.text.body}`,
                ":hover": { backgroundColor: "#e4e5e9" },
                fontWeight: 700,
                alignSelf: "center",
              }}
              disabled={otp === ""}
              onClick={handleVerifyEmail}
              variant="contained">
              Verify
            </Button>
          ) : (
            <LoadingButton
              sx={{
                width: 200,
                paddingTop: 1,
                paddingBottom: 1,
                borderRadius: 5,
                marginBottom: 3,
                display: "flex",
                justifyContent: "center",
              }}
              loading
              loadingPosition="start"
              startIcon={<SaveIcon />}
              variant="outlined">
              Verify
            </LoadingButton>
          )}
        </Box>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description">
          <Box sx={style}>
            <Typography
              variant="h4"
              sx={{ textAlign: "center", fontWeight: 600 }}>
              Create new password
            </Typography>
            <Typography sx={{ width: "100%", textAlign: "center" }}>
              Your new password must be different from previous used password.
            </Typography>
            <Box
              onClick={handleClose}
              sx={{ position: "absolute", right: 4, top: 4 }}>
              <ClearIcon />
            </Box>

            <FormControl sx={{ width: "100%" }}>
              <label>
                New password<span className="star-requied">*</span>
              </label>
              <OutlinedInput
                sx={{ width: "100%" }}
                required
                disabled={loading}
                type={showNew ? "password" : "text"}
                error={newPass.length < 8 && newPass.length > 0}
                onChange={(e) => setNewPass(e.target.value)}
                color={
                  newPass.length < 8 && newPass.length > 0 ? "error" : "success"
                }
                placeholder="New password"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      sx={{ fontSize: 5 }}
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end">
                      {showNew ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }></OutlinedInput>
              {newPass.length < 8 && newPass.length > 0 && (
                <Typography
                  sx={{
                    width: "100%",
                    color: "#f02849",
                    marginTop: -2,
                  }}>
                  The password must be at least 8 characters
                </Typography>
              )}
            </FormControl>
            <FormControl sx={{ width: "100%" }}>
              <label>
                Confirm new password<span className="star-requied">*</span>
              </label>
              <OutlinedInput
                sx={{ width: "100%" }}
                required
                disabled={loading}
                type={showConfirm ? "password" : "text"}
                error={newPass !== confirmPass && confirmPass.length > 0}
                onChange={(e) => setConfirmPass(e.target.value)}
                color={
                  newPass !== confirmPass && confirmPass.length > 0
                    ? "error"
                    : "success"
                }
                placeholder="Confirm new password"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      sx={{ fontSize: 5 }}
                      aria-label="toggle password visibility"
                      onClick={handleClickConfirmPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end">
                      {showConfirm ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }></OutlinedInput>
              {newPass !== confirmPass && confirmPass.length > 0 && (
                <Typography
                  sx={{
                    width: "100%",
                    color: "#f02849",
                    marginTop: -2,
                  }}>
                  Password does not match.
                </Typography>
              )}
            </FormControl>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}>
              {!loading ? (
                <Button
                  sx={{
                    paddingLeft: 4,
                    paddingRight: 4,
                    paddingTop: 1.5,
                    paddingBottom: 1.5,
                    borderRadius: 10,
                    margin: "auto",
                    backgroundColor: "#e4e5e9",
                    color: `${theme.palette.text.body}`,
                    ":hover": { backgroundColor: "#e4e5e9" },
                  }}
                  disabled={
                    newPass.length < 8 ||
                    confirmPass !== newPass ||
                    confirmPass === ""
                  }
                  variant="contained"
                  color="success"
                  onClick={handleResetPassword}>
                  RESET PASSWORD
                </Button>
              ) : (
                <LoadingButton
                  sx={{
                    paddingLeft: 4,
                    paddingRight: 4,
                    paddingTop: 1.5,
                    paddingBottom: 1.5,
                    borderRadius: 10,
                    margin: "auto",
                  }}
                  loading
                  loadingPosition="start"
                  startIcon={<SaveIcon />}
                  variant="outlined">
                  <span>RESET PASSWORD</span>
                </LoadingButton>
              )}
            </Box>
          </Box>
        </Modal>
      </Box>
    </Container>
  );
}

export default ValidateOtp;
